import React, { Component } from "react";
import { connect } from "react-redux";
import { accounting, updateObject } from "../../../shared";
import InputElement from "../../UI/Input/InputElement.js";

class LoanAmount extends Component {
	state = {
		formElement: null,
	};

	componentDidMount() {
		const { stateConfig } = this.props;
		if (stateConfig !== null) {
			// console.log("loading loan amount data for mount");
			this.loadAmounts(stateConfig);
		}
	}

	componentDidUpdate(prevProps) {
		const { stateConfig } = this.props;
		if (
			(stateConfig !== prevProps.stateConfig && stateConfig !== null) ||
			(this.state.formElement != null &&
				this.props.formElement.value !== prevProps.formElement.value &&
				this.props.formElement.value !== this.state.formElement.value)
		) {
			this.loadAmounts(stateConfig);
		}
	}

	loadAmounts = (stateConfig) => {
		const options = [
			{ value: "0", displayValue: "Select One...", disabled: "disabled" },
		];
		let { formElement } = this.props;
		for (
			let i = stateConfig.minimumLoanAmount;
			i <= stateConfig.maximumLoanAmount;
			i += stateConfig.loanStepAmount
		) {
			const money = accounting.formatMoney(i, "$", 0);
			options.push({ value: i, displayValue: money });
		}

		if (stateConfig.state === "TN") {
			const index = options.findIndex((x) => x.value === 5000);
			if (index > -1) {
				options.splice(index, 1);
			}
		}
		formElement = updateObject(formElement, {
			elementConfig: {
				options,
			},
			value: formElement.value,
		});

		this.setState({ formElement });
	};

	render() {
		const formElement =
			this.state.formElement !== null
				? this.state.formElement
				: this.props.formElement;
		document.getElementById("loanAmount") &&
			(document.getElementById("loanAmount").disabled = false);
		return (
			<InputElement
				formElement={formElement}
				onChange={(event) => this.props.changeHandler(event, "loanAmount")}
				onBlurred={(event) => this.props.blurHandler(event, "loanAmount")}
			/>
		);
	}
}

const mapStateToProps = (state) => ({
	app: state.app,
	form: state.form,
	config: state.config,
});

export default connect(mapStateToProps)(LoanAmount);
