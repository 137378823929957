import updateObject from "../../shared/utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
	analyticsData: null,
	app: null,
	appError: null,
	bootstrapError: null,
	config: null,
	isBootstrapping: false,
	loading: false,
	trackedFields: null,
	terms: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.BOOTSTRAP_APP_START:
			return {
				...state,
				isBootstrapping: true,
			};
		case actionTypes.BOOTSTRAP_APP_SUCCESS:
			return {
				...state,
				analyticsData: action.payload.analyticsData,
				config: action.payload.configData,
				isBootstrapping: false,
			};
		case actionTypes.BOOTSTRAP_APP_FAILURE:
			return {
				...state,
				bootstrapError: action.error,
				isBootstrapping: false,
			};
		case actionTypes.SAVE_APP_START:
			return {
				...state,
				loading: true,
			};
		case actionTypes.SAVE_APP_SUCCESS:
			return {
				...state,
				loading: false,
				app: action.payload,
				appError: null,
			};
		case actionTypes.SAVE_APP_FAIL:
			return {
				...state,
				appError: action.error,
				loading: false,
			};
		case actionTypes.TRACK_FIELDS_START:
			return {
				...state,
				trackedFields: action.payload,
			};
		case actionTypes.APP_COUNT_CHANGE:
			return updateObject(state, {
				app: updateObject(state.app, { singleOrCoApp: action.payload }),
			});
		case actionTypes.SAVE_TERMS:
			return updateObject(state, {
				terms: action.payload,
			});
		case actionTypes.HEADER_ANALYTICS_START:
			return {
				...state,
				loading: true,
			};
		case actionTypes.HEADER_ANALYTICS_SUCCESS:
			return {
				...state,
				loading: false,
				analyticsData: action.payload,
			};
		case actionTypes.HEADER_ANALYTICS_FAIL:
			return {
				...state,
				loading: false,
			};
		case actionTypes.DIGIFI_START:
			return {
				...state,
				loading: true,
			};
		case actionTypes.DIGIFI_SUCCESS:
			return {
				...state,
				loading: false,
				app: action.payload,
			};
		case actionTypes.DIGIFI_FAIL:
			return {
				...state,
				loading: false,
			};
		default:
			return state;
	}
};

export default reducer;
