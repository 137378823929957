import axios from "../axios-data";

export const loadConfiguration = async () => {
	try {
		const response = await axios.get("/config");
		return response.data.map((item, index) => ({
			...item,
			selected: index === "0",
		}));
	} catch (err) {
		throw err;
	}
};
