const cachedToken = null;

export function getDeviceToken() {
	if (cachedToken !== null) {
		return cachedToken;
	}

	const storedToken = window.localStorage.getItem("device");

	if (storedToken !== null) {
		return JSON.parse(storedToken);
	}

	const bytes = new Uint32Array(1);
	const randomValues = window.crypto.getRandomValues(bytes)[0];
	const deviceToken = randomValues.toString(16);

	window.localStorage.setItem("device", JSON.stringify(deviceToken));
	return deviceToken;
}
