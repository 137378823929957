import axios from "../axios-data";

export const saveAnalytics = async (headers) => {
	try {
		const response = await axios.post("/analytics", headers);
		return {
			date: response.data.date,
			ip: response.data.ipAddress,
			sessionId: response.data.sessionId,
			...headers,
		};
	} catch (err) {
		console.log(err);
		throw err;
	}
};
