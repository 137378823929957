const defaultFilterKeys = [
	"street1",
	"street2",
	"ssn",
	"coStreet1",
	"coStreet2",
	"coSsn",
];

export function sanitizeAppData(data = {}, filterKeys = defaultFilterKeys) {
	if (!data || data == null) {
		return {};
	}

	if (typeof data !== "object") {
		return data;
	}

	const dataKeys = Object.keys(data);

	if (!dataKeys.length > 0) {
		return {};
	}

	const uniqueKeys = new Set([...filterKeys]);

	return dataKeys.reduce((acc, key) => {
		if (!uniqueKeys.has(key)) {
			if (typeof data[key] === "object") {
				acc[key] = sanitizeAppData(data[key], filterKeys);
			} else {
				acc[key] = data[key];
			}
		}
		return acc;
	}, {});
}
