import React, { Component } from "react";
import "./App.css";
import ReactGA from "react-ga";
// import { loadReCaptcha } from "react-recaptcha-v3";
import { connect } from "react-redux";
import {
	Redirect,
	Route,
	BrowserRouter as Router,
	Switch,
} from "react-router-dom";
import { browserHistory } from "./app-insights";
import { Wizard } from "./components";
import Footer from "./components/Footer/Footer.js";
import Header from "./components/Header/Header.js";
import Qualified from "./components/Wizard/Steps/Qualified";
import * as actions from "./store/actions/actions";
import headerAnalytics from "./utility/headerAnalytics";

const DEFAULT_CONFIG = {
	trackingId: process.env.REACT_APP_GA_KEY,
	debug: process.env.REACT_APP_GA_DEBUG,
	gaOptions: {
		cookieDomain: "none",
	},
};

class App extends Component {
	constructor(props) {
		super(props);

		ReactGA.initialize([DEFAULT_CONFIG]);
		ReactGA.pageview("/apps/prequal/start");
	}

	async componentDidMount() {
		await this.props.bootstrapApp(headerAnalytics());
		// loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE);
	}

	render() {
		return (
			<Router basename={"/apps/prequal"} history={browserHistory}>
				<div className="App">
					<Header />
					<div className="pq-container">
						<Switch>
							<Redirect from="/branchSelect/" to="/" exact />
							<Route path="/branchSelect/:globalId" component={Qualified} />
							<Route path="/:id*/" exact component={Wizard} />
						</Switch>
					</div>
					<Footer />
				</div>
			</Router>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	bootstrapApp: (data) => dispatch(actions.bootstrapApp(data)),
});

export default connect(null, mapDispatchToProps)(App);
