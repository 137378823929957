import {
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	HStack,
	Heading,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { Component } from "react";
import { InputElement } from "../../UI";
import stepHandler from "../StepHandler.js";
class Finances extends Component {
	constructor() {
		super();
		this.state = { loading: false, hidden: false, showVerifiableInput: false };
	}
	componentDidMount() {
		this.props.trackFields("Finance", [
			"monthlyPayment",
			"numberOfDependents",
			"rentOrOwn",
			"incomeAmount",
			"verifiableIncome",
		]);
		window.scrollTo(0, 0);
	}

	render() {
		const nextButton = document.getElementById("next-button");
		const allRadio = document.getElementById("all");
		const someRadio = document.getElementById("some");

		nextButton && (nextButton.disabled = true);
		if (
			this.props.form.rentOrOwn.value !== "" &&
			this.props.form.monthlyPayment.value !== "" &&
			this.props.form.numberOfDependents.value !== "" &&
			this.props.form.verifiableIncome.value !== "" &&
			this.props.form.verifiableIncome.value !== null &&
			this.props.form.incomeAmount.value !== ""
		) {
			nextButton.disabled = false;
		}

		if (this.props.form.rentOrOwn.value == "neither") {
			this.props.form.monthlyPayment.value = "0";
		}

		if (
			this.props.form.verifiableIncome.value !== "" &&
			this.props.form.verifiableIncome.value ==
				this.props.form.incomeAmount.value
		) {
			allRadio && (allRadio.checked = true);
		}
		if (
			this.props.form.verifiableIncome.value !==
				this.props.form.incomeAmount.value &&
			this.props.form.verifiableIncome.value &&
			allRadio.checked == false
		) {
			someRadio && (someRadio.checked = true);
		}

		return (
			<Box mt={10} mb={20}>
				<Center>
					<Box maxW="600px">
						<Center>
							<Heading fontSize={18} mb={14}>
								Tell us about your household and income
							</Heading>
						</Center>
						<Box mb={10}>
							<Text fontSize="18px" as="b">
								HOUSEHOLD
							</Text>
						</Box>
						<Box mt={10}>
							<Text fontSize="18px" as="b">
								Do you own or rent your residence?
							</Text>
						</Box>

						<Box ml={2}>
							<InputElement
								formElement={this.props.form.rentOrOwn}
								onChange={(event) => this.props.onChanged(event, "rentOrOwn")}
							/>{" "}
						</Box>
						{this.props.form.rentOrOwn.value == "neither" ? null : (
							<>
								<Box mt={14}>
									<Text fontSize="18px" as="b">
										How much is your monthly mortgage/rent?
									</Text>
								</Box>
								<Box maxW="150px" mt={5}>
									<InputElement
										formElement={this.props.form.monthlyPayment}
										onChange={(event) =>
											this.props.onChanged(event, "monthlyPayment")
										}
									/>
								</Box>
							</>
						)}

						<Box mt={14}>
							<Text fontSize="18px" as="b">
								How many dependents do you have?
							</Text>{" "}
						</Box>
						<Text color="#777777" lineHeight="21px">
							A dependent is anyone you claim on your taxes or anyone that is
							financially dependent on you. Don’t include yourself.
						</Text>
						<Box maxW="150px" mt={5}>
							<InputElement
								formElement={this.props.form.numberOfDependents}
								onChange={(event) =>
									this.props.onChanged(event, "numberOfDependents")
								}
							/>
						</Box>
						<Box mt={14} mb={14}>
							<Divider />
						</Box>
						<Text fontSize="18px" as="b">
							INCOME
						</Text>
						<Box mt={14}>
							<Text fontSize="18px" as="b">
								What is the total monthly take-home pay for your household?
							</Text>{" "}
						</Box>
						<Text color="#777777" lineHeight="21px">
							This includes income for you and your spouse or other
							income-earning members of your household after taxes and
							deductions.
						</Text>
						<Box maxW="150px" mt={5}>
							<InputElement
								formElement={this.props.form.incomeAmount}
								onChange={(event) =>
									this.props.onChanged(event, "incomeAmount")
								}
							/>
						</Box>
						<Box mt={14}>
							<Text fontSize="18px" as="b">
								How much of the household monthly take-home pay can you verify?
							</Text>{" "}
						</Box>
						<Text color="#777777" lineHeight="21px">
							Income verification documents includes recent paycheck stubs,
							recipient award or benefit letter, or bank statements. For
							self-employed applicants: the last 2 years of tax returns and the
							previous 6 month of bank statements.
						</Text>
					</Box>
				</Center>
				<Box mr={8}>
					<Container mt={14} maxWidth={"600px"}>
						<Box>
							<Box>
								<HStack mb={3}>
									<input
										type="radio"
										id="all"
										onClick={() => {
											this.props.form.verifiableIncome.value =
												this.props.form.incomeAmount.value;
											this.setState({ showVerifiableInput: false });
										}}
										name="radio"
									/>
									<Box>
										<Text ml={2}>
											I can verify{" "}
											<b>
												<i>all </i>
											</b>{" "}
											of it
										</Text>
									</Box>
								</HStack>

								<HStack>
									<input
										type="radio"
										id="some"
										onClick={() => {
											this.props.form.verifiableIncome.value = null;
											this.setState({ showVerifiableInput: true });
										}}
										name="radio"
									/>
									<Box>
										<Text ml={2}>
											I can verify{" "}
											<b>
												<i> some </i>
											</b>{" "}
											of it
										</Text>
									</Box>
								</HStack>
								{someRadio && someRadio.checked ? (
									<Box mt={10}>
										<Box>
											{" "}
											<Text>Amount you can verify</Text>
										</Box>
										<Box maxW="150px">
											<InputElement
												formElement={this.props.form.verifiableIncome}
												onChange={(event) =>
													this.props.onChanged(event, "verifiableIncome")
												}
											/>
										</Box>
									</Box>
								) : null}
							</Box>
						</Box>
					</Container>{" "}
				</Box>

				<Center>
					<Divider mt={10} mb={10} maxW="600px" />
				</Center>
				<Container maxWidth={"600px"} mb={10}>
					<Flex>
						<Button
							id="back-button"
							onClick={() => {
								this.props.save();
								this.props.previous();
							}}
							bg="white"
							color="#2371a5"
							_hover={{ bg: "#3c99d3", color: "white" }}
							size="lg"
							mt={10}
							border="1px"
							p={4}
						>
							Back
						</Button>
						<Spacer />
						<Button
							onClick={() => {
								this.setState({ loading: true });
								this.props.next();
							}}
							id="next-button"
							bg="#2371a5"
							color="white"
							_hover={{ bg: "#3c99d3" }}
							size="lg"
							mt={10}
							isLoading={this.state.loading}
							loadingText="Continue"
							p={4}
						>
							Continue
						</Button>
					</Flex>
				</Container>
			</Box>
		);
	}
}

export default stepHandler(Finances);
