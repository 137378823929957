import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions/actions";

import { Box, HStack, Text } from "@chakra-ui/react";

const Borrowers = (props) => {
	let radio = null;

	return (
		<div
			className={props.selected ? "choice active" : "choice"}
			data-toggle="wizard-radio"
			onClick={() => radio.click()}
		>
			<input
				ref={(node) => {
					radio = node;
				}}
				type="checkbox"
				name="borrower"
				value={props.value}
				checked={props.selected}
				onClick={props.onSelected}
				readOnly
			/>
			<div className="card card-checkboxes">
				<i className={props.imageClass} />
				<i className={props.imageClass2} />
				<p>{props.description}</p>
			</div>
		</div>
	);
};

class SingleOrCoApp extends Component {
	onSingleOrCoApp = (e) => {
		this.props.onChanged(e, "singleOrCoApp");
		this.props.onApplicantCountChange(e.target.value);
	};

	render() {
		return (
			<Box mt={5} mb={10} pt={10}>
				<Text fontSize="16px" as="b">
					{" "}
					Who is applying for this loan?{" "}
				</Text>
				<HStack pt={10}>
					<Box
						width={"150px"}
						height={"80px"}
						pt={5}
						border="1px"
						className="border1 border2"
						borderColor="#D3D3D3"
					>
						<Box mt={"-38px"}>
							<Borrowers
								description="Myself"
								imageClass="ti-user boxes"
								value="Single"
								selected={this.props.form.singleOrCoApp.value === "Single"}
								onSelected={(e) => this.onSingleOrCoApp(e)}
							/>
						</Box>
					</Box>

					<Box
						width={"150px"}
						height={"30px"}
						pt={5}
						className="border1 border2"
						border="1px"
						borderColor="#D3D3D3"
					>
						<Box mt={"-62px"}>
							<Borrowers
								description="Two of Us"
								imageClass="ti-user border1"
								imageClass2="ti-user border2"
								value="Co-Applicant"
								selected={
									this.props.form.singleOrCoApp.value === "Co-Applicant"
								}
								onSelected={(e) => this.onSingleOrCoApp(e)}
							/>
						</Box>
					</Box>
				</HStack>
			</Box>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	onApplicantCountChange: (e) => dispatch(actions.onApplicantCountChange(e)),
});

export default connect(null, mapDispatchToProps)(SingleOrCoApp);
