import {
	Box,
	Button,
	Center,
	Checkbox,
	Container,
	Divider,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { Component } from "react";
import stepHandler from "../StepHandler";

const trackedFields = ["electronicConsent", "telemarketingConsent"];

class ApplicantConsent extends Component {
	state = {
		url: process.env.REACT_APP_WEBSITE_URL,
		// recaptchaInit: false,
		// recaptchaVerified: false,
		loading: false,
	};
	componentDidMount() {
		this.props.trackFields("About You", trackedFields);
		window.scrollTo(0, 0);
	}
	render() {
		const nextButton = document.getElementById("next-button");

		//Form "Next" validation check
		nextButton.disabled = true;
		if (
			this.props.form.electronicConsent.value == "true" ||
			this.props.form.loanPurpose.value == "AutoSecuredLoan"
		) {
			nextButton.disabled = false;
		}

		return (
			<Box>
				<Center>
					<Box maxW="600px">
						<Center>
							<Heading size="md" mb={14} mt={10}>
								Review the following consents
							</Heading>
						</Center>

						<Text fontSize="lg" as="b">
							Electronic Consents
						</Text>

						<Text fontSize="18px">
							<br />
							Please read and review the below consent which allows you to
							receive, review, and sign documents electronically with Republic
							Finance and agree to Republic Finance’s Terms of Use, Online
							Privacy Policy, and Consumer Privacy Notice. If you agree, please
							check the box to continue.{" "}
							{this.props.form.loanPurpose.value == "AutoSecuredLoan"
								? "(optional)"
								: "(required)"}
						</Text>

						<Container mt={25} mb={10} maxW="600px" bg="#F5F5F5" p={6}>
							<Box p={10}>
								{" "}
								<Grid templateColumns="repeat(15, 1fr)" gap={1}>
									<GridItem colSpan={1}>
										<Checkbox
											style={{ transform: "scale(0.7)" }}
											size="xl"
											onChange={(event) =>
												this.props.onChanged(event, "electronicConsent")
											}
											onBlur={(event) =>
												this.props.onBlurred(event, "electronicConsent")
											}
										></Checkbox>
									</GridItem>
									<GridItem colSpan={14}>
										<Box>
											<Text fontSize="18px">
												By checking this box and clicking "Continue", I agree
												that I reviewed the{" "}
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/esign-consent"
												>
													Consent for Electronic Signatures and Records
												</Link>
												; confirm that I can view and keep the electronic
												records described there; and consent to the use and
												terms of electronic records and signatures as described
												there. Additionally, by checking this box and clicking
												"Continue", I agree that: (i){" "}
												<span style={{ fontWeight: "bold" }}>
													I received, read, understood and consent to the{" "}
												</span>
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/terms-of-use"
												>
													Terms of Use
												</Link>{" "}
												<span style={{ fontWeight: "bold" }}>
													(including the{" "}
												</span>{" "}
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/terms-of-use#arbitration"
												>
													AGREEMENT TO ARBITRATION
												</Link>
												<span style={{ fontWeight: "bold" }}>
													{" "}
													(Section 15) and{" "}
												</span>
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/terms-of-use#juryTrial"
												>
													JURY TRIAL WAIVER
												</Link>
												<span style={{ fontWeight: "bold" }}>
													{" "}
													(Section 16) contained therein);{" "}
												</span>
												and (ii) I received and read Republic Finance's{" "}
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/privacy-policy"
												>
													Online Privacy Policy
												</Link>{" "}
												and{" "}
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/privacy-notice"
												>
													{" "}
													U.S. Consumer Privacy Notice.
												</Link>{" "}
											</Text>
										</Box>
									</GridItem>
								</Grid>
							</Box>
						</Container>
						<br />
						<Text fontSize="18px" as="b">
							Give us permission to contact you
						</Text>
						<Text fontSize="18px">
							<br />
							Automated phone calls and texts are the quickest way for us to
							contact you about this offer and future offers.{" "}
							{this.props.form.loanPurpose.value == "AutoSecuredLoan" ? (
								"(optional)"
							) : (
								<span style={{ fontWeight: "bold" }}>
									(Note: If you don’t provide consent, we won’t be able to call
									or text you about your pre-qualification) (optional){" "}
								</span>
							)}{" "}
						</Text>
						<Container mt={25} maxW="600px" bg="#F5F5F5">
							<Box p={10}>
								<Grid templateColumns="repeat(15, 1fr)" gap={1}>
									<GridItem colSpan={1}>
										<Checkbox
											style={{ transform: "scale(0.7)" }}
											size="xl"
											onChange={(event) =>
												this.props.onChanged(event, "telemarketingConsent")
											}
											onBlur={(event) =>
												this.props.onBlurred(event, "telemarketingConsent")
											}
										></Checkbox>
									</GridItem>
									<GridItem colSpan={14}>
										<Box>
											{" "}
											<Text fontSize="18px" fontWeight="bold" lineHeight="24px">
												{" "}
												By providing my telephone number(s) in connection with
												this request for pre-qualification and checking this
												box, I consent to receive calls, text messages,
												artificial or pre-recorded voice messages, and voicemail
												transmissions, including calls made by an automated
												telephone dialing system or an automated system for the
												selection or dialing of telephone numbers or the playing
												of a recorded message when a connection is completed to
												a number called, by or on behalf of Republic Finance,
												regarding its products and services, including
												telephonic sales calls and/or marketing communications.
												I authorize these telemarketing calls and texts even if
												I have previously included my telephone number(s) on any
												Do-Not-Call registry or list. I understand I am not
												required to provide this consent as condition of
												purchasing any products or service from Republic
												Finance. I certify that I have authority to provide this
												consent because I am the regular user of the telephone
												number(s) as the subscriber or a non-subscriber
												customary user who has authority to consent to these
												communications. I also agree to notify Republic Finance
												if my telephone number(s) change. I have read,
												understand, and agree to Republic Finance’s{" "}
												<Link
													style={{
														textDecoration: "underline",
														color: "#2171A6",
													}}
													isExternal
													color="teal.500"
													href="/legal/text-message-policy"
												>
													{" "}
													Text Message (SMS) Terms of Use.
												</Link>{" "}
											</Text>
										</Box>
									</GridItem>
								</Grid>
							</Box>
						</Container>
						<Box mt={5}>
							<Divider pt={5} />
						</Box>
						<Box mb={20}>
							<Flex>
								{this.props.form.loanPurpose.value ==
								"AutoSecuredLoan" ? null : (
									<Button
										id="back-button"
										onClick={() => {
											this.props.save();
											this.props.previous();
										}}
										bg="white"
										color="#2371a5"
										_hover={{ bg: "#3c99d3", color: "white" }}
										size="lg"
										border="1px"
										mt={10}
										p={4}
									>
										Back
									</Button>
								)}

								<Spacer />
								{/* if auto secured, go to review page */}
								<Button
									onClick={() => {
										this.props.form.loanPurpose.value == "AutoSecuredLoan"
											? (this.props.save(), this.props.jumpToStep(3))
											: this.props.next();

										this.setState({ loading: true });
									}}
									mt={10}
									id="next-button"
									bg="#2371a5"
									color="white"
									_hover={{ bg: "#3c99d3" }}
									size="lg"
									minW="50px"
									isLoading={this.state.loading}
									loadingText="Continue"
									p={4}
								>
									Continue
								</Button>
							</Flex>
						</Box>
					</Box>
				</Center>
			</Box>
		);
	}
}

export default stepHandler(ApplicantConsent);
