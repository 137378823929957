import { appInsights } from "../../app-insights";
import { sanitizeAppData } from "../../utility/sanitizeAppData";

export function eventInsightsMiddleware(store) {
	return (next) => (action) => {
		const { payload = {}, type } = action;

		// Rely on the errorMiddleware to handle the failure cases.
		if (action.type.includes("FAILURE")) {
			return next(action);
		}

		const { app, config, ...currentState } = store.getState();

		const sanitizedAppData = sanitizeAppData(app);
		const sanitizedPayload = sanitizeAppData(payload);

		appInsights.trackEvent({
			name: `Redux-Action: ${type}`,
			properties: {
				actionName: type,
				actionPayload: JSON.stringify(sanitizedPayload),
				applicationState: JSON.stringify({
					...currentState,
					app: sanitizedAppData,
				}),
			},
		});

		return next(action);
	};
}
