import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import { errorMiddleware, eventInsightsMiddleware } from "./store/middleware";
import reducer from "./store/reducers/reducers";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
	reducer,
	composeEnhancers(
		applyMiddleware(
			thunkMiddleware, // lets us dispatch() functions
			errorMiddleware,
			eventInsightsMiddleware,
		),
	),
);

export default store;
