import {
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	Heading,
	Link,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { Component } from "react";
import { InputElement } from "../../UI";
import stepHandler from "../StepHandler.js";

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

class Welcome extends Component {
	constructor() {
		super();
		this.state = { loading: false };
	}
	componentDidMount() {
		this.props.trackFields("Welcome", ["zipCode"]);
		window.scrollTo(0, 0);
	}

	handleSubmit = (event) => {
		event.preventDefault();
		if (this.props.form?.zipCode?.validationResults?.isValid) {
			this.setState({ loading: true });
			this.props.next();
		}
	};

	render() {
		const { isTransitionPending } = this.props;

		if (document.getElementById("validResult") && !isTransitionPending) {
			document.getElementById("next-button").disabled = false;
		}

		const validated =
			this.props.form?.zipCode?.validationResults?.isValid ||
			(this.props.form?.state.value &&
				this.props.form?.zipCode?.value.length === 5)
				? true
				: false;

		return (
			<Box mt={10} mb={20}>
				<Center>
					<Heading id="welcome" size="lg" mb={14} as="h1">
						Prequalify for a loan in just 5 minutes with{" "}
						<span style={{ color: "#2171A6", fontStyle: "italic" }}>
							no impact to your credit score!
						</span>
					</Heading>
				</Center>

				<Container maxW="600px">
					<Box>
						<Text as="b" fontSize="xl">
							<Text as="h2">
								{" "}
								Thank you for considering Republic Finance for your financial
								needs!
							</Text>
						</Text>{" "}
						<Text fontSize="18px" mt={5}>
							The prequalification process is quick and easy. We’ll ask some
							basic information about you, your household, and your household
							income. We’ll then perform a{" "}
							<Link
								style={{ textDecoration: "underline", color: "#2171A6" }}
								isExternal
								color="teal.500"
								href="https://www.consumerfinance.gov/ask-cfpb/whats-a-credit-inquiry-en-1317/"
							>
								soft credit check
							</Link>{" "}
							so that means{" "}
							<span style={{ fontWeight: "bold" }}>
								{" "}
								your credit score is never affected!
							</span>{" "}
							You may be able to redeem your offer online or in your local
							Republic Finance branch.{" "}
							<Text fontSize="18px" mt={5}>
								We don’t yet offer loans in all states, so we’ll make sure we
								service your area, first.
							</Text>
						</Text>
						<Box mt={10} maxW={"150px"}>
							<form onSubmit={this.handleSubmit}>
								<InputElement
									formElement={this.props.form.zipCode}
									onChange={(event) => this.props.onChanged(event, "zipCode")}
									onBlur={(event) => this.props.onBlurred(event, "zipCode")}
								/>
							</form>
						</Box>
					</Box>
				</Container>
				<Center>
					{" "}
					{this.props.form?.zipCode?.validationResults?.isValid ? (
						<Box margin={5} p={10} maxW="600px" bg="#F8FCFF">
							It looks like you’re in{" "}
							<span style={{ fontWeight: "bold" }}>
								{this.props.form.zipCode.validationResults.validationSuccess}.{" "}
							</span>{" "}
							We have locations near you!
						</Box>
					) : null}
				</Center>
				<Center>
					{" "}
					{this.props.form?.zipCode?.validationResults?.validationErrors ? (
						<Box margin={5} padding={10} maxW="500px" bg="#FFFBEF">
							{this?.props?.form?.zipCode?.validationResults?.validationErrors}{" "}
							{this?.props?.form?.zipCode?.validationResults?.validationLink ? (
								<a
									style={{ textDecoration: "underline", color: "#2171A6" }}
									href={`${WEBSITE_URL}/check`}
								>
									loan check page.
								</a>
							) : (
								""
							)}
						</Box>
					) : (
						""
					)}
				</Center>
				<Center>
					<Divider mt={10} maxW="600px" />
				</Center>
				<Container>
					<Flex>
						<Spacer />

						<Button
							id="next-button"
							onClick={() => {
								this.setState({ loading: true });
								this.props.next();
							}}
							disabled={!validated || isTransitionPending}
							bg="#2371a5"
							color="white"
							_hover={{ bg: "#3c99d3" }}
							size="m"
							mt={10}
							p={4}
							isLoading={this.state.loading}
							loadingText="Continue"
						>
							Continue
						</Button>
					</Flex>
				</Container>
			</Box>
		);
	}
}

export default stepHandler(Welcome);
