import { Box, Button, Center, Text } from "@chakra-ui/react";
import React, { Component, Fragment } from "react";
import axios from "../../axios-data";
import Branch from "./Branch";
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

class BranchSelect extends Component {
	state = {
		loading: false,
		isError: false,
		branches: null,
		branch: null,
		zip: null,
		previousZip: null,
	};

	async componentDidUpdate() {
		const zipChanged = await this.isZipCodeUpdated();
		if (zipChanged) {
			await this.setBranches();
		}
	}

	async isZipCodeUpdated() {
		if (
			this.props.zipCode.length == 5 &&
			this.state.previousZip != null &&
			this.state.previousZip.length == 5 &&
			this.state.zip != null &&
			this.state.zip.length == 5 &&
			this.state.zip != this.props.zipCode
		) {
			const currentZip = this.state.zip;
			this.setState({ previousZip: currentZip, zip: this.props.zipCode });
			return true;
		}
		return false;
	}

	async setBranches() {
		try {
			axios.get(`/branch/near/${this.props.zipCode}`).then((response) => {
				const newBranches = response.data.map((item) => ({
					...item,
				}));
				if (newBranches.length > 0) {
					this.setState({
						branches: newBranches,
						branch: newBranches[0].id,
					});
					this.props.onBranchSelected(newBranches[0].id);
				} else {
					document.getElementById("zipError").innerHTML =
						"We don't have any branches near that area. Try entering a different ZIP code.";
				}
			});
		} catch (e) {
			console.error(e);
			window.location.replace(`${WEBSITE_URL}}apps/prequal/downtime-error`);
		}
	}

	async componentDidMount() {
		this.setState({ zip: this.props.zipCode, previousZip: this.props.zipCode });
		await this.setBranches();
	}

	render() {
		const { branches, branch } = this.state;
		const { selectedBranch, onBranchSelected, marketingContactConsent } =
			this.props;

		let branchSelect = <p>Loading branches...</p>;

		if (this.props.isQualified && branches !== null && branch !== null) {
			const isSelected = (item) =>
				item.id === selectedBranch.id || item.id === selectedBranch;

			branchSelect = (
				<Box p={20}>
					<Center>
						<Box mb={10}>
							<Text as="b" fontSize="3xl">
								We’ve sent your information to your local branch!
							</Text>{" "}
						</Box>
					</Center>
					<Center>
						<Box maxW={"600px"} mb={10}>
							{marketingContactConsent ? (
								<Text>
									One of our team members will contact you to start your loan
									application. If you submitted your information after business
									hours or on the weekend, we’ll reach out to you on the next
									business day. Also, feel free to call or visit us during our
									hours of operation. Congratulations on your offer!
								</Text>
							) : (
								<Text>
									Please call or visit us to start your loan application.
									Congratulations on your offer!
								</Text>
							)}
						</Box>
					</Center>
					<Center>
						<Box
							m={5}
							width={"350px"}
							minW={"280px"}
							bg="#F5F5F5"
							p={7}
							borderRadius="6px"
						>
							{" "}
							<Text fontSize="2xl" as="b" ml={5}>
								YOUR LOCAL BRANCH
							</Text>
							<Box pl={5} mt={5}>
								<p>
									{branches[branches.findIndex(isSelected)].address} <br />
									{branches[branches.findIndex(isSelected)].city},{" "}
									{branches[branches.findIndex(isSelected)].state}{" "}
									{branches[branches.findIndex(isSelected)].zipCode} <br />
									{branches[branches.findIndex(isSelected)].hoursOfOperation}{" "}
									<br />
									{branches[branches.findIndex(isSelected)].phone}
								</p>
							</Box>
						</Box>
					</Center>

					<Center>
						<Button
							onClick={() => window.location.replace(`${WEBSITE_URL}`)}
							bg="#2371a5"
							color="white"
							_hover={{ bg: "#3c99d3" }}
							size="lg"
							mt={10}
							p={8}
						>
							Go to Homepage
						</Button>
					</Center>
				</Box>
			);
		} else {
			if (branches !== null && branch !== null) {
				branchSelect = branches.map((item, key) => (
					<Center>
						<Box m={5} width={"350px"}>
							<Branch
								{...item}
								key={key}
								selected={
									selectedBranch.id
										? selectedBranch.id == item.id
										: branches[0].id == item.id
								}
								value={item}
								onSelected={onBranchSelected}
							/>
						</Box>{" "}
					</Center>
				));
			}
		}

		return (
			<Fragment>
				<Center>
					{!this.props.isQualified ? (
						<p id="zipError" className="qual-zipcode-error" />
					) : null}
				</Center>

				{branchSelect}
			</Fragment>
		);
	}
}

export default BranchSelect;
