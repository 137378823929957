import { checkboxAnatomy as parts } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system";

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(parts.keys);

// default base style from the Checkbox theme
const baseStyle = definePartsStyle({
	control: {
		width: "2rem",
		height: "2rem",
		marginRight: "15px",
		marginTop: "5px",
		borderColor: "#777777",
	},
});

export const checkboxTheme = defineMultiStyleConfig({
	baseStyle,
});
