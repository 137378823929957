import moment from "moment";
import React, { Component, Fragment } from "react";
import ReactGA from "react-ga";
import { connect } from "react-redux";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

import { appInsights } from "../../app-insights";
import { updateObject } from "../../shared";
import * as actions from "../../store/actions/actions";
import states from "../../store/constants";
import { checkValidity } from "../../utility/forms/form-functions";
import headerAnalytics from "../../utility/headerAnalytics";
import {
	ElectronicConsentLabel,
	TelemarketingConsentLabel,
} from "../ConsentLabels";

const stepHandler = (WrappedComponent) => {
	const wrapper = () =>
		class extends Component {
			// used to fire functions after state is updated.
			functionQueue = [];
			pageViewStart = 0;

			state = {
				form: {
					loanAmount: {
						// label: 'How much would you like to borrow?',
						elementType: "loanAmount",
						elementConfig: {
							options: [
								{
									value: "0",
									displayValue: "Loading...",
									disabled: "disabled",
								},
							],
							id: "loanAmount",
						},
						value: "0",
						validation: {
							required: true,
							isLoanAmount: true,
						},

						touched: false,
					},
					loanPurpose: {
						// label: 'How do you plan to use this money?',
						elementType: "select",
						elementConfig: {
							options: [
								{ value: "Appliances", displayValue: "Appliances" },
								{ value: "Auto Repairs", displayValue: "Auto Repairs" },
								{ value: "Consolidation", displayValue: "Consolidation" },
								{ value: "Holidays", displayValue: "Holidays" },
								{
									value: "Home Improvements",
									displayValue: "Home Improvements",
								},
								{ value: "Home Repairs", displayValue: "Home Repairs" },
								{ value: "Legal Fees", displayValue: "Legal Fees" },
								{ value: "Pay Bills", displayValue: "Pay Bills" },
								{
									value: "Vehicle/Watercraft Purchase",
									displayValue: "Vehicle/Watercraft Purchase",
								},
								{ value: "Unexpected Bills", displayValue: "Unexpected Bills" },
								{ value: "Vacation", displayValue: "Vacation" },
								{ value: "Other", displayValue: "Other" },
							],
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},
					leadAggregation: {
						// label: 'How did you hear about us?',
						elementType: "select",
						elementConfig: {
							options: [
								{
									value: "Present or former customer",
									displayValue: "Present or former customer",
								},
								{ value: "Email", displayValue: "Email" },
								{
									value: "Received a check in the mail",
									displayValue: "Received a check in the mail",
								},
								{ value: "Radio", displayValue: "Radio" },
								{ value: "Social Media", displayValue: "Social Media" },
								{ value: "TV", displayValue: "TV" },
								{ value: "Internet search", displayValue: "Internet search" },
								{
									value: "Friend or family member",
									displayValue: "Friend or family member",
								},
								{ value: "Other", displayValue: "Other" },
							],
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},
					firstName: {
						label: "First Name",
						elementType: "input",
						elementConfig: {
							mask: (s) => Array.from(s).map(() => /[^0-9]/g, ""),
							guide: false,
							type: "text",
							placeholder: "John",
							keepCharPositions: true,
							autoComplete: "given-name",
							autoFocus: "autoFocus",
							id: "firstName",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 50,
							//isName: true
						},
						touched: false,
					},
					lastName: {
						label: "Last Name",
						elementType: "input",
						elementConfig: {
							mask: (s) => Array.from(s).map(() => /[^0-9]/g, ""),
							guide: false,
							type: "text",
							placeholder: "Doe",
							autoComplete: "family-name",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 50,
							//isName: true
						},
						touched: false,
					},
					suffix: {
						label: "Suffix ",
						elementType: "input",
						elementConfig: {
							type: "text",
							placeholder: "Jr.",
							autoComplete: "honorific-suffix",
						},
						value: "",
						validation: {
							required: false,
							maxLength: 5,
						},
						touched: false,
					},
					street1: {
						label: " Street Address",
						elementType: "input",
						elementConfig: {
							type: "text",
							placeholder: "16117 Orange Castle Street",
							autoComplete: "street-address",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 128,
						},
						touched: false,
					},
					street2: {
						label: "Apt, Suite, etc. ",
						elementType: "input",
						elementConfig: {
							type: "text",
							placeholder: "Apt #404",
							autoComplete: "address-line2",
						},
						value: "",
						validation: {
							required: false,
							maxLength: 128,
						},
						touched: false,
					},
					city: {
						label: "City",
						elementType: "input",
						elementConfig: {
							type: "text",
							disabled: false,
							autoComplete: "address-line3",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 75,
						},
						touched: false,
					},
					state: {
						label: "State",
						elementType: "select",
						elementConfig: {
							options: states,
							disabled: true,
							autoComplete: "address-line1",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 2,
						},
						touched: false,
					},
					zipCode: {
						label: "ZIP code",
						elementType: "input",
						inputMode: "numeric",
						elementConfig: {
							type: "text",
							maxLength: 5,
							id: "zipCode",
							disabled: true,
							autoComplete: "postal-code",
						},
						value: "",
						validation: {
							isZip: {
								enabled: true,
								onValidateSuccess: (zip) => {
									this.functionQueue.push(() => this.prefillFormZipCode(zip));
								},
							},
						},
						touched: false,
					},
					ssn: {
						label: "SSN",
						elementType: "ssn",
						inputMode: "numeric",
						elementConfig: {
							type: "password",
							mask: [
								/\d/,
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							],
							guide: false,
							autoComplete: "off",
							id: "ssn",
						},
						value: "",
						validation: {
							required: true,
							isSSN: true,
						},
						touched: false,
					},
					dateOfBirth: {
						label: "Date of Birth (mm/dd/yyyy)",
						elementType: "input",
						inputMode: "numeric",
						elementConfig: {
							type: "text",
							placeholder: "",
							mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
							guide: false,
							pipe: createAutoCorrectedDatePipe("mm/dd/yyyy"),
							keepCharPositions: true,
							autoComplete: "bday",
						},
						value: "",
						validation: {
							is18: true,
							required: true,
						},
						touched: false,
					},
					email: {
						label: "Email",
						elementType: "email",
						inputMode: "email",
						elementConfig: {
							type: "email",
							autoComplete: "email",
						},
						value: "",
						validation: {
							required: true,
							isEmail: true,
							maxLength: 128,
						},
						touched: false,
					},
					phone: {
						label: "Phone",
						elementType: "input",
						inputMode: "tel",
						elementConfig: {
							type: "text",
							mask: [
								"(",
								/[1-9]/,
								/\d/,
								/\d/,
								")",
								" ",
								/\d/,
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							],
							guide: false,
							placeholder: "(123) 456-7890",
							autoComplete: "tel-national",
						},
						value: "",
						validation: {
							required: true,
							isPhone: true,
						},
						touched: false,
					},
					singleOrCoApp: {
						value: "",
						validation: {
							required: true,
							singleOrCoApp: true,
						},
					},
					coFirstName: {
						label: "First Name",
						elementType: "input",
						elementConfig: {
							mask: (s) => Array.from(s).map(() => /[^0-9]/g, ""),
							guide: false,
							type: "text",
							placeholder: "John",
							autoComplete: "given-name",
							autoFocus: "autoFocus",
							id: "firstName",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 50,
							//isName: true,
						},
						touched: false,
					},
					coLastName: {
						label: "Last Name",
						elementType: "input",
						elementConfig: {
							mask: (s) => Array.from(s).map(() => /[^0-9]/g, ""),
							guide: false,
							type: "text",
							placeholder: "Doe",
							autoComplete: "family-name",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 50,
							//isName: true
						},
						touched: false,
					},
					coSuffix: {
						label: "Suffix",
						elementType: "input",
						elementConfig: {
							type: "text",
							placeholder: "Jr.",
							autoComplete: "honorific-suffix",
						},
						value: "",
						validation: {
							required: false,
							maxLength: 5,
						},
						touched: false,
					},
					sameAddressAsPrimary: {
						label: `  Address same as primary applicant`,
						elementType: "checkbox",
						style: { marginLeft: "10px" },
						elementConfig: {
							radioClass: "radio-inline",
							options: [
								{ value: "true", displayValue: "Yes" },
								{ value: "false", displayValue: "No" },
							],
							unwrap: "true",
						},
						validation: {
							required: true,
							isCoAppAddress: true,
						},
						value: "false",
						touched: false,
					},
					coStreet1: {
						label: "Street Address",
						elementType: "input",
						elementConfig: {
							type: "text",
							placeholder: "16117 Orange Castle Street",
							autoComplete: "street-address",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 128,
						},
						touched: false,
					},
					coStreet2: {
						label: "Apt, Suite, etc. ",
						elementType: "input",
						elementConfig: {
							type: "text",
							placeholder: "Apt #404",
							autoComplete: "address-line2",
						},
						value: "",
						validation: {
							required: false,
							maxLength: 128,
						},
						touched: false,
					},
					coCity: {
						label: "City",
						elementType: "input",
						elementConfig: {
							type: "text",
							autoComplete: "address-line3",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 75,
						},
						touched: false,
					},
					coState: {
						label: "State",
						elementType: "select",
						elementConfig: {
							options: states,
							autoComplete: "address-line1",
						},
						value: "",
						validation: {
							required: true,
							maxLength: 2,
						},
						touched: false,
					},
					coZipCode: {
						label: "ZIP code",
						elementType: "input",
						inputMode: "numeric",
						elementConfig: {
							type: "text",
							mask: [/\d/, /\d/, /\d/, /\d/, /\d/],
							guide: false,
							autoComplete: "postal-code",
						},
						value: "",
						validation: {
							required: true,
							isZip: true,
						},
						touched: false,
					},
					coSsn: {
						label: "SSN",
						elementType: "ssn",
						inputMode: "numeric",
						elementConfig: {
							type: "password",
							mask: [
								/\d/,
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							],
							guide: false,
							autoComplete: "off",
							id: "ssn",
						},
						value: "",
						validation: {
							required: true,
							// isCoSSN: true
							isSSN: true,
						},
						touched: false,
					},
					coDateOfBirth: {
						label: "Date of Birth (mm/dd/yyyy)",
						elementType: "input",
						inputMode: "numeric",
						elementConfig: {
							type: "text",
							placeholder: "",
							mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
							guide: false,
							pipe: createAutoCorrectedDatePipe("mm/dd/yyyy"),
							keepCharPositions: true,
							autoComplete: "bday",
						},
						value: "",
						validation: {
							is18: true,
							required: true,
						},
						touched: false,
					},
					coEmail: {
						label: "Email",
						elementType: "email",
						elementConfig: {
							type: "email",
							autoComplete: "email",
						},
						value: "",
						validation: {
							required: true,
							isEmail: true,
							maxLength: 128,
						},
						touched: false,
					},
					coPhone: {
						label: "Phone",
						elementType: "input",
						inputMode: "tel",
						elementConfig: {
							type: "text",
							mask: [
								"(",
								/[1-9]/,
								/\d/,
								/\d/,
								")",
								" ",
								/\d/,
								/\d/,
								/\d/,
								"-",
								/\d/,
								/\d/,
								/\d/,
								/\d/,
							],
							guide: false,
							placeholder: "(123) 456-7890",
							autoComplete: "tel-national",
						},
						value: "",
						validation: {
							required: true,
							isPhone: true,
						},
						touched: false,
					},
					monthlyPayment: {
						// label: 'How much is your monthly mortgage/rent?',
						elementType: "input",
						inputMode: "numeric",
						elementConfig: {
							mask: createNumberMask({
								prefix: "$",
								includeThousandsSeparator: true,
								allowDecimal: true,
								integerLimit: 8,
							}),
							type: "text",
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},
					numberOfDependents: {
						// label: 'How many dependents do you have?',
						elementType: "select",
						elementConfig: {
							options: [
								{ value: "0", displayValue: "0" },
								{ value: "1", displayValue: "1" },
								{ value: "2", displayValue: "2" },
								{ value: "3", displayValue: "3" },
								{ value: "4", displayValue: "4" },
								{ value: "5", displayValue: "5 or more" },
							],
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},

					rentOrOwn: {
						// label: 'Do you own or rent your residence?',
						elementType: "radio",
						elementConfig: {
							radioClass: "radio",
							options: [
								{ value: "own", displayValue: " Own" },
								{ value: "rent", displayValue: " Rent" },
								{
									value: "neither",
									displayValue: " I don't pay a mortgage or rent",
								},
							],
							unwrap: "true",
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},

					verifiableIncome: {
						// label: 'How much of the amount stated above can you verify?',
						elementType: "verifiableIncome",
						inputMode: "numeric",
						elementConfig: {
							type: "text",
							mask: createNumberMask({
								prefix: "$",
								includeThousandsSeparator: true,
								allowDecimal: true,
								integerLimit: 8,
							}),
						},
						value: "",
						validation: {
							required: true,
							isVerifiableIncome: true,
						},
						touched: false,
					},

					incomeAmount: {
						// label: 'What is the total monthly take home pay for your household?',
						elementType: "input",
						inputMode: "numeric",
						elementConfig: {
							id: "incomeAmount",
							type: "text",
							mask: createNumberMask({
								prefix: "$",
								includeThousandsSeparator: true,
								allowDecimal: true,
								integerLimit: 8,
							}),
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},
					incomeType: {
						label: "Income Type",
						elementType: "select",
						elementConfig: {
							options: [
								{ value: "hourly", displayValue: "Hourly" },
								{ value: "salary", displayValue: "Salary" },
								{ value: "retired", displayValue: "Retired" },
								{ value: "selfEmployed", displayValue: "Self-Employed" },
							],
						},
						value: "",
						validation: {
							required: true,
						},
						touched: false,
					},
					verifiableIncome: {
						// label: 'How much of the amount stated above can you verify?',
						elementType: "verifiableIncome",
						inputMode: "numeric",
						elementConfig: {
							type: "text",
							mask: createNumberMask({
								prefix: "$",
								includeThousandsSeparator: true,
								allowDecimal: true,
								integerLimit: 8,
							}),
						},
						value: "",
						validation: {
							required: true,
							isVerifiableIncome: true,
						},
						touched: false,
					},
					electronicConsent: {
						label: <ElectronicConsentLabel />,
						elementType: "consent",
						elementConfig: {
							type: "checkbox",
							id: "electronicConsent",
							unwrap: "true",
							isTimestamp: true,
						},
						id: "electronicConsent",
						value: null,
						checked: false,
						validation: {
							required: true,
							isChecked: true,
						},
						touched: false,
					},
					telemarketingConsent: {
						label: <TelemarketingConsentLabel />,
						elementType: "consent",
						elementConfig: {
							id: "telemarketingConsent",
							type: "checkbox",
							unwrap: "true",
							isTimestamp: true,
						},
						id: "telemarketingConsent",
						value: null,
						validation: {
							required: false,
						},
						touched: false,
					},
					coElectronicConsent: {
						label: <ElectronicConsentLabel />,
						elementType: "consent",
						elementConfig: {
							type: "checkbox",
							id: "electronicConsent",
							unwrap: "true",
							isTimestamp: true,
						},
						id: "electronicConsent",
						value: null,
						checked: false,
						validation: {
							required: true,
							isChecked: true,
						},
						touched: false,
					},
					coTelemarketingConsent: {
						label: <TelemarketingConsentLabel />,
						elementType: "consent",
						elementConfig: {
							id: "telemarketingConsent",
							type: "checkbox",
							unwrap: "true",
							isTimestamp: true,
						},
						id: "telemarketingConsent",
						value: null,
						validation: {
							required: false,
						},
						touched: false,
					},
					creditReportConsent: {
						label: "",
						elementType: "creditReportConsent",
						elementConfig: {
							id: "creditReportConsent",
							type: "checkbox",
							unwrap: "true",
							isTimestamp: true,
						},
						value: null,
						id: "creditReportConsent",
						content: (
							<span style={{ fontWeight: "normal" }}>
								I authorize Republic Finance to obtain my consumer reports from
								any consumer reporting agency to determine if I pre-qualify for
								a loan.
							</span>
						),
						coContent: (
							<Fragment>
								We authorize Republic Finance to obtain my consumer reports from
								any consumer reporting agency to determine if I pre-qualify for
								a loan.
							</Fragment>
						),
						validation: {
							required: true,
							isChecked: true,
						},
						touched: false,
					},
					branch: {
						value: "",
					},
					headerAnalyticsId: {
						value: "",
					},
					// recaptchaVerified: {
					// 	value: null,
					// 	readOnly: true,
					// 	validation: {
					// 		required: true,
					// 		mustBeTrue: {
					// 			validationMessage:
					// 				"We're sorry, but we cannot serve bots here.",
					// 		},
					// 	},
					// },
				},
				isTransitionPending: false,
			};

			componentDidMount() {
				this.pageViewStart = Date.now();

				const { app } = this.props;
				if (app !== undefined && app !== null) {
					this.loadStateFromProps(app);
				}
			}

			componentDidUpdate(prevProps) {
				const { app, analytics } = this.props;
				if (app !== prevProps.app) {
					this.loadStateFromProps(app);
				}
				if (analytics !== prevProps.analytics) {
					const theElement = updateObject(this.state.form.headerAnalyticsId, {
						value: analytics.sessionId,
					});
					const updatedForm = updateObject(this.state.form, {
						headerAnalyticsId: theElement,
					});
					this.setState({ form: updatedForm });
				}
			}

			componentWillUnmount() {
				const pageViewInSeconds = Math.floor(
					(Date.now() - this.pageViewStart) / 1000,
				);
				const danglingSecondsFromMinute = pageViewInSeconds % 60;
				const formattedSeconds = `${danglingSecondsFromMinute < 10 ? "0" : ""}${danglingSecondsFromMinute}`;
				const pageViewDuration =
					Number.parseInt(pageViewInSeconds / 60) + ":" + formattedSeconds;

				appInsights.trackPageView({
					name: this.props.trackedFields.currentStep,
					pageType: "Prequal Wizard Step",
					properties: {
						pageViewDuration,
					},
				});

				this.pageViewStart = 0;
			}

			trackFields = (currentStep, fieldNames, update = false) => {
				const fields = {};
				const { onTrackFields } = this.props;
				const { form } = this.state;

				for (let i = 0; i < fieldNames.length; i += 1) {
					fields[fieldNames[i]] = form[fieldNames[i]];
				}
				const fieldTracker = { fieldNames, currentStep };

				if (update === false) {
					ReactGA.pageview(`/apps/prequal/${currentStep}`);
				}

				onTrackFields(fieldTracker);
			};

			formElementIsValid = (formElement) =>
				formElement.validationResults === undefined ||
				formElement.validationResults.isValid === undefined ||
				formElement.validationResults.isValid;

			saveData = (passedSessionId = null) => {
				const {
					id,
					trackedFields: { currentStep },
					onSaveApp,
				} = this.props;

				const { form } = this.state;
				const formData = {
					id,
				};

				let newValue = null;
				for (const formElementIdentifier in form) {
					if (form[formElementIdentifier].readOnly) continue;
					newValue = form[formElementIdentifier].value;

					// Force analytics ID to populate if passed from validate
					if (
						formElementIdentifier === "headerAnalyticsId" &&
						(newValue === null || newValue === "")
					) {
						newValue = passedSessionId ?? this.props.analytics?.sessionId;
					}

					// the input is a checkbox and has a timestamp config, we set the current time into the value.
					if (this.isTimestampField(form[formElementIdentifier])) {
						newValue =
							form[formElementIdentifier].value !== null &&
							form[formElementIdentifier].value !== false &&
							form[formElementIdentifier].value !== "false"
								? moment().format("L")
								: null;
					}

					formData[formElementIdentifier] = newValue;
				}

				const postForm = {
					id,
					...formData,
					currentStep,
				};

				return onSaveApp(postForm);
			};

			validateSessionId = async () => {
				let hasSessionId = false;

				if (this.props.analytics.sessionId) {
					hasSessionId = true;
				}

				return hasSessionId;
			};

			reprofile(session_id) {
				const head = document.getElementsByTagName("head").item(0);
				const script = document.createElement("script");
				script.setAttribute("id", "sessionProfile");
				script.setAttribute("type", "text/javascript");
				script.setAttribute(
					"src",
					`https://h.online-metrix.net/tags.js?org_id=7kcny31f&session_id=${session_id}&allow_reprofile=1`,
				);

				if (!document.getElementById("sessionProfile")) {
					head.appendChild(script);
				}
			}

			formElementIsValid = (formElement) =>
				formElement.validationResults !== undefined &&
				formElement.validationResults.isValid;

			handleNextStepTransition = ({ afterTransition } = {}) => {
				this.setState({ isTransitionPending: true });
				this.props.next().then((response) => {
					// If response is true then we are proceeding to the next step meaning we will get a new instance of this component
					// calling this would cause memory issues settingState on an unmounted component
					if (response !== true) {
						this.setState({ isTransitionPending: false });
					} else if (afterTransition && typeof afterTransition === "function") {
						afterTransition();
					}
				});
				//    console.log('resp', response);
			};

			inputChangedHandler = async (event, inputIdentifier) => {
				const currentTarget = event.target;
				const { form } = this.state;
				const updatedForm = {
					...form,
				};

				updatedForm[inputIdentifier] = await this.updateFormElement(
					inputIdentifier,
					currentTarget.type === "checkbox" && currentTarget.name !== "borrower"
						? currentTarget.checked.toString()
						: currentTarget.value,
					updatedForm,
				);

				let eventChange = `${inputIdentifier}-Invalid : ${updatedForm[inputIdentifier].value}`;
				if (updatedForm[inputIdentifier].validationResults.isValid === true) {
					eventChange = `${inputIdentifier}-Valid : ${updatedForm[inputIdentifier].value}`;
				}

				let formIsValid = true;
				for (const formElement in updatedForm) {
					formIsValid =
						this.formElementIsValid(updatedForm[formElement]) && formIsValid;
				}

				this.setState({ form: updatedForm, formIsValid }, () =>
					this.handlePostSaveValidationEvents(),
				);
			};

			inputBlurredHandler = (inputIdentifier) => {
				const { form } = this.state;
				const updatedForm = {
					...form,
				};
				const updatedFormElement = {
					...updatedForm[inputIdentifier],
				};

				// google analytics event only after modified and valid
				if (
					updatedFormElement.touched &&
					this.formElementIsValid(updatedFormElement)
				) {
					ReactGA.event({
						category: "Field updated",
						action: updatedFormElement.label,
					});
				}
			};

			loadConfigForState = () => {
				if (
					this.props.config !== null &&
					this.props.app !== null &&
					this.props.app.state !== null
				) {
					const stateConfig = this.props.config.filter(
						(c) => c.state === this.props.app.state,
					);
					if (stateConfig.length > 0) {
						return stateConfig[0];
					}
				}
				return null;
			};

			validateForm = async () => {
				const { form } = this.state;

				const updatedForm = {
					...form,
				};

				let formIsValid = true;
				for (const formElementIdentifier in updatedForm) {
					if (
						this.props.trackedFields.fieldNames.indexOf(
							formElementIdentifier,
						) === -1
					) {
						continue;
					}

					const updatedFormElement = {
						...updatedForm[formElementIdentifier],
					};
					updatedFormElement.validationResults = await checkValidity(
						updatedFormElement.value,
						updatedFormElement.validation,
					);

					updatedFormElement.touched = true;
					updatedForm[formElementIdentifier] = updatedFormElement;

					formIsValid =
						this.formElementIsValid(updatedFormElement) && formIsValid;
				}

				this.setState({ form: updatedForm, formIsValid });
				return formIsValid;
			};

			prefillFormZipCode = async (zipCodeData) => {
				const { form } = this.state;
				const updatedForm = {
					...form,
				};

				updatedForm.city = await this.updateFormElement(
					"city",
					zipCodeData.city,
					updatedForm,
					false,
				);
				updatedForm.state = await this.updateFormElement(
					"state",
					zipCodeData.stateCode,
					updatedForm,
					false,
				);
				updatedForm.branch = await this.updateFormElement(
					"branch",
					zipCodeData.branchNumber,
					updatedForm,
					false,
				);

				const obj = this.props.config.find(
					(o) => o.state === zipCodeData.stateCode,
				);
				if (form.loanAmount.value !== "0" && form.loanAmount.value !== 0) {
					updatedForm.loanAmount = await this.updateFormElement(
						"loanAmount",
						obj.minimumLoanAmount.toString(),
						updatedForm,
						false,
					);
				}

				this.setState({ form: updatedForm });
			};

			async isValidated() {
				const formIsValid = await this.validateForm();
				let currentSessionId = this.props.analytics?.sessionId;

				if (await this.validateSessionId()) {
					this.reprofile(this.props.analytics.sessionId);
				} else {
					const updatedAnalytics = await this.props.onAnalyticsSave(
						headerAnalytics(),
					);
					currentSessionId = updatedAnalytics.sessionId;
					await this.reprofile(updatedAnalytics.sessionId);
				}

				if (!formIsValid) return false;
				return this.saveData(currentSessionId);
			}

			loadStateFromProps(app) {
				const { form } = this.state;
				let updatedForm = {
					...form,
				};

				if (app != null) {
					for (const element in app) {
						let theElement = updateObject(form[element], {
							value: app[element],
							touched: false,
						});

						if (this.isTimestampField(form[element])) {
							theElement = updateObject(theElement, {
								checked: app[element] !== null || app[element] === "true",
							});
						}

						updatedForm = updateObject(updatedForm, {
							[element]: theElement,
						});
					}
				}

				this.setState({ form: updatedForm });
			}

			isTimestampField = (formElement) =>
				typeof formElement !== "undefined" &&
				typeof formElement.elementConfig !== "undefined" &&
				typeof formElement.elementConfig.isTimestamp !== "undefined" &&
				formElement.elementConfig.type === "checkbox";

			handlePostSaveValidationEvents() {
				while (this.functionQueue.length > 0) {
					this.functionQueue.shift()();
				}
			}

			async updateFormElement(
				elementName,
				value,
				updatedForm,
				validate = true,
				touched = true,
			) {
				const updatedFormElement = {
					...updatedForm[elementName],
				};

				updatedFormElement.value = value;
				if (validate) {
					updatedFormElement.validationResults = await checkValidity(
						updatedFormElement.value,
						updatedFormElement.validation,
					);
				}

				updatedFormElement.touched = touched;

				return updatedFormElement;
			}

			// Update the form imperatively based on the response from the backend API.
			// This is entirely used for location the formatting errors in the About Section
			// This should be generalized in the future
			updateFormForAPIError = (errorObject, refs) => {
				// Reverse the keys so the inputs that are highest in the viewport come first and
				// both of the potential error fields are shown on screen
				const errorKeys = Object.keys(errorObject).reverse();
				const updatedForm = errorKeys.reduce((acc, key) => {
					const formattedKey = key.toLowerCase();
					acc[formattedKey] = {
						...acc[formattedKey],
						validationResults: {
							isValid: false,
							validationErrors: errorObject[key][0],
						},
					};
					return acc;
				}, this.state.form);

				// Scroll the first errored field into view because it is above the top of the viewport on mobile always
				this.setState({ form: updatedForm }, () => {
					const firstError = errorKeys[0].toLowerCase();
					if (refs[firstError]) {
						refs[firstError].scrollIntoView({
							behavior: "smooth",
						});
					}
				});
			};

			render() {
				const stateConfig = this.loadConfigForState();
				const { form, isTransitionPending } = this.state;
				const { next: passedNextMethod, ...restOfProps } = this.props;
				return (
					<WrappedComponent
						{...restOfProps}
						form={form}
						isTransitionPending={isTransitionPending}
						validate={this.validateForm}
						onChanged={this.inputChangedHandler}
						onBlurred={this.inputBlurredHandler}
						next={this.handleNextStepTransition}
						save={this.saveData}
						trackFields={this.trackFields}
						config={stateConfig}
						updateFormForAPIError={this.updateFormForAPIError}
					/>
				);
			}
		};

	return connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(
		wrapper(),
	);
};

const mapStateToProps = (state) => ({
	loading: state.loading,
	app: state.app,
	appError: state.appError,
	id: state.id,
	config: state.config,
	trackedFields: state.trackedFields,
	analytics: state.analyticsData,
});

const mapDispatchToProps = (dispatch) => ({
	onAnalyticsSave: (appData) => dispatch(actions.analyticsApp(appData)),
	onSaveApp: (appData) => dispatch(actions.saveApp(appData)),
	onTrackFields: (appData) => dispatch(actions.trackFieldsStart(appData)),
});

export default stepHandler;
