const DATE_REGEX =
	/(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/;
const EMAIL_REGEX =
	/^(([^<>()[\]\.,;:\s@\"+]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;
const NAME_REGEX = /^[A-Za-z]+$/;
const NUMERIC_REGEX = /^\d+$/;
const PHONE_REGEX = /^\(\d{3}\)\s\d{3}-\d{4}/; // (000) 000-0000
const SSN_REGEX = /^\d{3}-\d{2}-\d{4}$/;
const ZIPCODE_REGEX = /^\d{5}$/; // 12345

export const hasValue = (value) =>
	value !== null && value.toString().trim() !== "";

export const isAlphabetic = (value) =>
	hasValue(value) && NAME_REGEX.test(value);
export const isEmail = (value) => EMAIL_REGEX.test(value);
export const isFormattedDate = (value) => DATE_REGEX.test(value);
export const isNumeric = (value) => NUMERIC_REGEX.test(value);
export const isPhone = (value) => PHONE_REGEX.test(value);
export const isSSN = (value) => SSN_REGEX.test(value);
export const isZipCode = (value) => ZIPCODE_REGEX.test(value);

export function validateMinimumAge(value, minimumAge) {
	const isDate = isFormattedDate(value);

	if (!isDate) {
		return {
			isValid: false,
			validationErrors: "Please enter a valid date",
		};
	}

	const difference = Date.now() - new Date(value).getTime();
	const age = Math.abs(new Date(difference).getUTCFullYear() - 1970);

	if (age < minimumAge || difference < 0) {
		return {
			isValid: false,
			validationErrors:
				"Unfortunately, you do not meet our age requirements for pre-qualification.",
		};
	}

	return { isValid: true, validationErrors: "" };
}
