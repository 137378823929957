import {
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	Heading,
	Link,
	Spacer,
	Stack,
	Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import InputElement from "../../UI/Input/InputElement.js";
import LoanAmount from "../Controls/LoanAmount.js";
import stepHandler from "../StepHandler.js";

const Purpose = ({
	config,
	form,
	onChanged,
	onBlurred,
	trackFields,
	jumpToStep,
	save,
	previous,
	next,
}) => {
	const [loading, setLoading] = useState(false);
	const [isContinueDisabled, setIsContinueDisabled] = useState(true);

	useEffect(() => {
		trackFields("Purpose", ["loanAmount", "loanPurpose", "leadAggregation"]);
		window.scrollTo(0, 0);
	}, [trackFields]);

	useEffect(() => {
		const { loanAmount, loanPurpose, leadAggregation } = form;

		const isLoanAmountZero = loanAmount.value.trim() === "0";
		const isLoanPurposeValid = loanPurpose.value !== "";
		const isLeadAggregationValid = leadAggregation.value !== "";

		setIsContinueDisabled(
			isLoanAmountZero || !isLoanPurposeValid || !isLeadAggregationValid,
		);
	}, [
		form.loanAmount.value,
		form.loanPurpose.value,
		form.leadAggregation.value,
	]);

	return (
		<>
			<Container maxW="600px" mt={10}>
				<Center>
					<Heading mb={14} style={{ fontSize: "18px" }}>
						Tell us about your financial needs
					</Heading>
				</Center>
			</Container>

			<Center>
				<Stack>
					{" "}
					<Text fontWeight="semibold">How much would you like to borrow?</Text>
					<Box maxW="290px">
						<LoanAmount
							stateConfig={config}
							formElement={form.loanAmount}
							changeHandler={onChanged}
							blurHandler={onBlurred}
						/>
					</Box>
					<Box maxW="600px" bg="#F5F5F5" mt={20} p={8}>
						<Text fontSize="14px" as="b">
							Interested in borrowing more?
						</Text>
						<Text fontSize="14px">
							With an auto-secured loan, your vehicle is provided as collateral,
							which could qualify you for a larger loan amount and lower
							interest rates.
						</Text>
						<Box mt={3}>
							<Link
								onClick={() => {
									jumpToStep(2);
								}}
							>
								<Text color={"#2171A6"} as="u">
									Learn more about auto-secured loans
								</Text>
							</Link>
						</Box>
					</Box>
					<Box maxW="360px">
						<Text fontWeight={"semibold"} my={4}>
							How do you plan to use this money?
						</Text>
						<InputElement
							formElement={form.loanPurpose}
							onChange={(event) => onChanged(event, "loanPurpose")}
							onBlur={(event) => onBlurred(event, "loanPurpose")}
						/>
					</Box>
					<Box maxW="360px">
						<Text fontWeight={"semibold"} my={4}>
							How did you hear about us?
						</Text>
						<InputElement
							formElement={form.leadAggregation}
							onChange={(event) => onChanged(event, "leadAggregation")}
							onBlur={(event) => onBlurred(event, "leadAggregation")}
						/>
					</Box>
				</Stack>
			</Center>

			<Center>
				<Divider mt={10} maxW="600px" />
			</Center>

			<Container maxW="600px" mb={10}>
				<Flex>
					<Button
						id="back-button"
						onClick={() => {
							save();
							previous();
						}}
						bg="white"
						color="#2371a5"
						_hover={{ bg: "#3c99d3", color: "white" }}
						size="lg"
						mt={10}
						p={4}
						border="1px"
					>
						Back
					</Button>
					<Spacer />
					<Button
						onClick={() => {
							if (!isContinueDisabled) {
								save();
								next();
								this.setState({ loading: true });
							}
						}}
						id="next-button"
						bg="#2371a5"
						color="white"
						_hover={{ bg: "#3c99d3" }}
						size="lg"
						mt={10}
						p={4}
						minW="50px"
						isLoading={loading}
						loadingText="Continue"
						disabled={isContinueDisabled}
					>
						Continue
					</Button>
				</Flex>
			</Container>
		</>
	);
};

export default stepHandler(Purpose);
