import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { createBrowserHistory } from "history";

import { getDeviceToken } from "./utility/getDeviceToken";

const reactPlugin = new ReactPlugin();

export const browserHistory = createBrowserHistory({
	basename: "/apps/prequal",
});

export const appInsights = new ApplicationInsights({
	config: {
		enableAjaxErrorStatusText: true,
		enableAjaxPerfTracking: true,
		enableRequestHeaderTracking: true,
		enableResponseHeaderTracking: true,
		enableUnhandledPromiseRejectionTracking: true,
		excludeRequestFromAutoTrackingPatterns: [
			"https://www-google.analytics.com*",
			"https://stats.g.doubleclick.net*",
			"https://api.feefo.com*",
			"/qualify/*.hot-update.json",
		],
		extensionConfig: {
			[reactPlugin.identifier]: { history: browserHistory },
		},
		extensions: [reactPlugin],
		instrumentationKey: process.env.REACT_APP_INSTRUMENT_KEY,
	},
});

export function initializeAppInsights() {
	appInsights.loadAppInsights();
	appInsights.addTelemetryInitializer((envelope) => {
		envelope.data = {
			...(envelope.data || {}),
			deviceToken: getDeviceToken(),
		};
	});
}
