import React from "react";
// import propTypes from "prop-types";
import Input from "./Input";

const InputElement = (props) => {
	const { domRef, formElement, onChange, onBlur, readOnly } = props;

	if (formElement === undefined) {
		return null;
	}

	return (
		<Input
			domRef={domRef}
			label={formElement.label}
			elementType={formElement.elementType}
			elementConfig={formElement.elementConfig}
			value={formElement.value}
			validationResults={formElement.validationResults}
			validation={formElement.validation}
			touched={formElement.touched}
			changed={onChange}
			blurred={onBlur}
			readOnly={readOnly}
			content={formElement.content}
			coContent={formElement.coContent}
			collapse={formElement.collapse}
			collapseId={formElement.collapseId}
			inputMode={formElement.inputMode}
		/>
	);
};

export default InputElement;
