import React, { Component } from "react";
import { connect } from "react-redux";
import StepZilla from "./StepZilla";
import {
	AboutYou,
	ApplicantConsent,
	AutoLoan,
	AutoLoanReview,
	CoApplicant,
	CoApplicantConsent,
	Consents,
	Finances,
	Purpose,
	Welcome,
} from "./Steps";

class Wizard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			steps: [
				{
					name: "Location",
					icon: "ti-face-smile",
					hideNextButton: true,
					component: <Welcome />,
				},

				{
					name: "Financial Needs",
					icon: "ti-help",
					enabled: true,
					hideNextButton: true,
					component: <Purpose />,
				},
				// 'AUTO LOAN' is not being used for the page label below.
				// jumpToStep is directing to this page, and the icon is being set manually in getNavStates.
				// This logic will need to be tweaked if adding/removing or changing the order of pages.
				{
					name: "AUTO LOAN",
					icon: "ti-user",
					enabled: false,
					hideNextButton: true,
					component: <AutoLoan />,
				},
				{
					name: "Thank You",
					icon: "ti-face-smile",
					enabled: false,
					hideNextButton: true,
					component: <AutoLoanReview />,
				},

				{
					name: "About You",
					icon: "ti-user",
					enabled: true,
					hideNextButton: true,
					component: <AboutYou />,
				},
				{
					name: "Applicant Consent",
					icon: "ti-envelope",
					enabled: true,
					hideNextButton: true,
					component: <ApplicantConsent />,
				},
				{
					name: "Co-Applicant",
					icon: "ti-user",
					enabled: false,
					hideNextButton: true,
					component: <CoApplicant />,
				},
				{
					name: "Co-Applicant Consent",
					icon: "ti-envelope",
					enabled: false,
					hideNextButton: true,
					component: <CoApplicantConsent />,
				},
				{
					name: "Finances",
					icon: "ti-money",
					enabled: true,
					hideNextButton: true,
					component: <Finances />,
				},

				{
					name: "Final Consent",
					icon: "ti-envelope",
					hideNextButton: true,
					enabled: true,
					component: <Consents />,
				},
			],
			isSafari: false,
		};
	}

	componentDidUpdate(prevProps) {
		const { app } = this.props;
		const { steps } = this.state;

		if (app !== prevProps.app) {
			if (
				prevProps.app !== null &&
				app.singleOrCoApp !== prevProps.app.singleOrCoApp
			) {
				// user toggled/changed coapp choice.  Had to manually rebuild array because setState
				// was async and fired AFTER the wizard progressed passed enabled/disabled step

				const newSteps = steps.map((item) => {
					const newItem = { ...item };
					if (item.name === "Co-Applicant") {
						newItem.enabled = app.singleOrCoApp === "Co-Applicant";
					}
					if (item.name === "Co-Applicant Consent") {
						newItem.enabled = app.singleOrCoApp === "Co-Applicant";
					}
					return newItem;
				});

				// Yea, this is usually a no-no
				// eslint-disable-next-line react/no-did-update-set-state
				this.setState({ steps: newSteps });
			}
		}
	}
	componentDidMount() {
		const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

		this.setState({ isSafari });

		if (isSafari) {
			const style = document.createElement("style");

			style.type = "text/css";

			style.innerHTML = `.pq-body .nav-pills > li.active > a:after { margin-top: -67px; }`;

			document.head.appendChild(style);
		}
	}

	render() {
		const { steps, isSafari } = this.state;
		return (
			<div className={`wizard-container ${isSafari ? "safari-margin" : ""}`}>
				<div className="card wizard-card" data-color="blue" id="wizardProfile">
					<StepZilla
						steps={steps}
						preventEnterSubmission
						hocValidationAppliedTo={["*"]}
					/>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	loading: state.loading,
	app: state.app,
});

export default connect(mapStateToProps, null)(Wizard);
