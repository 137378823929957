import moment from "moment";
import React from "react";
import axios from "../../axios-data";
import store from "../../store";

import * as validators from "./validate";

const checkValidity = async (value, rules) => {
	let isValid = true;
	let validationErrors = "";
	let validationSuccess = "";
	let validationType = "";
	let validationLink = "";

	const updateValidationErrors = (errorMessage = "") => {
		if (isValid === false && validationErrors === "") {
			validationErrors = errorMessage;
		}
	};

	if (!rules) {
		return { isValid, validationErrors };
	}

	if (rules.required) {
		isValid = validators.hasValue(value) && isValid;
		updateValidationErrors("This field is required");
	}

	if (rules.maxLength) {
		isValid = value.length <= rules.maxLength && isValid;
		if (validators.hasValue(value)) {
			updateValidationErrors(
				`Must be no longer than ${rules.maxLength} characters`,
			);
		}
	}

	if (rules.isLoanAmount) {
		if (isValid === false || value === "0") {
			isValid = false;
			validationErrors = "This field is required";
		} else {
			isValid = true;
		}
	}

	if (rules.isVerifiableIncome) {
		const incomeAmount = document.getElementById("incomeAmount").value;
		isValid =
			Number.parseFloat(incomeAmount.replace(/[^0-9.]/g, "")) >=
			Number.parseFloat(value.replace(/[^0-9.]/g, ""));
		if (isValid === false) {
			validationErrors = "Verified income cannot be greater than total income.";
		}
	}

	if (rules.isName) {
		isValid = validators.isAlphabetic(value) && isValid;
		updateValidationErrors(
			"Please enter a valid name with only alphabetic characters",
		);
	}

	if (rules.isSSN) {
		isValid = validators.isSSN(value) && isValid;
		updateValidationErrors("Please enter a valid Social Security Number");
	}

	if (rules.isCoSSN) {
		const matchesPattern = validators.isSSN(value);
		const matchesApplicant = value === store.getState().app.ssn;

		isValid = matchesPattern && !matchesApplicant && isValid;
		if (isValid === false && value !== "") {
			if (!matchesPattern) {
				validationErrors = "Please enter a valid Social Security Number";
			} else if (matchesApplicant) {
				validationErrors =
					"Please enter a different Social Security Number than the original applicant";
			}
		}
	}

	if (rules.isEmail) {
		isValid = validators.isEmail(value) && isValid;
		updateValidationErrors("Please enter a valid email address");
	}

	if (rules.isPhone) {
		isValid = validators.isPhone(value) && isValid;
		updateValidationErrors("Please enter a valid phone number");
	}

	if (rules.isNumeric) {
		isValid = validators.isNumeric(value) && isValid;
		if (validators.hasValue(value)) {
			updateValidationErrors("Please enter a numeric value");
		}
	}

	if (rules.isChecked) {
		if (value === "true") {
			isValid = true;
		} else if (value === "false") {
			isValid = false;
			validationErrors = "You must agree to these terms before continuing";
		}
	}

	if (typeof rules.mustBeTrue !== "undefined") {
		if (
			typeof value !== "undefined" &&
			value !== null &&
			value.toString().trim() !== ""
		) {
			isValid = typeof value === "boolean" && value;
			if (isValid) {
				if (
					rules.onValidateSuccess &&
					typeof rules.onValidateSuccess === "function"
				) {
					rules.onValidateSuccess(value);
				}
			} else if (
				typeof rules.mustBeTrue === "object" &&
				typeof rules.mustBeTrue.validationMessage !== "string"
			) {
				validationErrors = "This value must be true for you to continue.";
			} else {
				validationErrors = rules.mustBeTrue.validationMessage;
			}
		}
	}

	if (rules.isZip && rules.isZip.enabled && rules.isZip.enabled === true) {
		isValid = validators.isZipCode(value) && isValid;
		if (isValid === true) {
			const validation = await lookupZipCode(value);
			isValid = validation.valid;
			if (isValid) {
				validationSuccess = validation.result;
				rules.isZip.onValidateSuccess(validation.data);
			} else {
				validationErrors = validation.result;
				validationLink = validation.link;
				validationType = validation.validationType;
			}
		} else {
			validationErrors = "Please enter your zip code.";
			validationType = "text-muted";
		}
	}

	if (rules.is18) {
		const configData = store.getState().config;
		const stateValue = store.getState().app.state;
		const [{ minimumAge }] = configData.filter(
			(item) => item.state === stateValue,
		);

		const result = validators.validateMinimumAge(value, minimumAge);
		isValid = result.isValid && isValid;
		updateValidationErrors(result.validationErrors);
	}

	return {
		isValid,
		validationErrors,
		validationSuccess,
		validationType,
		validationLink,
	};
};

const lookupZipCode = async (zipCode) => {
	const promise = new Promise((resolve, reject) => {
		axios
			.get(`branch/zipcode/${zipCode}`)
			.then((response) => {
				if (response.data.branchNumber === -999) {
					// zip in footprint, not yet active or deactivated.
					window.location.href = `https://www.sourcelinkdirect.com/RF_app/apply2.php?search=1&zip=${response.data.zipCode}`;
				} else if (response.data.branchNumber > 0) {
					// zip in footprint, active
					resolve({
						valid: true,
						data: response.data,
						result: `${response.data.city}, ${response.data.stateCode}`,
					});
				} else {
					// zip not in footprint
					resolve({
						valid: false,
						//validationType: 'text-primary italicized',
						result:
							"Unfortunately, we don't offer online loans in your ZIP code yet. If you've received a loan check in the mail and want to learn more, visit our ",
						link: "/check",
					});
				}
			})
			.catch((error) =>
				// zip not real or broke google
				resolve({
					valid: false,
					validationType: "text-muted",
					result:
						"Oops! We aren’t recognizing this as a valid ZIP code. Please enter a valid ZIP code.",
				}),
			);
	});

	const result = await promise;

	return result;
};

export { checkValidity, lookupZipCode };
