export default function headerAnalytics() {
	const headerAnalytics = {
		referrer: document.referrer,
		connectionType: (navigator.connection || {}).effectiveType,
		language: navigator.language || "",
		platform: navigator.platform || "",
		userAgent: navigator.userAgent,
		webAddress: window.location.href,
		pageOn: window.location.pathname,
		screenHeight: window.screen.height,
		screenWidth: window.screen.width,
		colorDepth: window.screen.colorDepth,
		pixelDepth: window.screen.pixelDepth,
		screenOrientation: (window.screen.orientation || {}).type,
		innerWidth: window.innerWidth,
		innerHeight: window.innerHeight,
	};
	return headerAnalytics;
}
