import axios from "axios";

let backendHost;

const hostname = window && window.location && window.location.hostname;

if (process.env.REACT_APP_API_URL) {
	backendHost = process.env.REACT_APP_API_URL;
} else if (
	hostname === "republicfinance.com" ||
	hostname === "www.republicfinance.com" ||
	hostname === "republicfinance.azurewebsites.net"
) {
	backendHost = "https://prequal.republicfinance.com";
} else if (
	hostname === "rf-customerportal-dev.azurewebsites.net" ||
	hostname === "rf-website-dev.azurewebsites.net"
) {
	backendHost = "https://rf-prequal-dev.azurewebsites.net";
} else if (hostname === "republicfinance-web-uat.azurewebsites.net") {
	backendHost = "https://republicfinance-prequal-uat.azurewebsites.net";
} else {
	backendHost = `${window.location.protocol}//${window.location.hostname}${
		window.location.port !== 80 && window.location.port !== 443
			? `:${window.location.port}`
			: ""
	}`;
}

const API_ROOT = `${backendHost}/api/`;

const instance = axios.create({
	baseURL: API_ROOT,
	withCredentials: false,
});

export default instance;
