export const BOOTSTRAP_APP_START = "BOOTSTRAP_APP_START";
export const BOOTSTRAP_APP_SUCCESS = "BOOTSTRAP_APP_SUCCESS";
export const BOOTSTRAP_APP_FAILURE = "BOOTSTRAP_APP_FAILURE";

export const SAVE_APP_START = "SAVE_APP_START";
export const SAVE_APP_SUCCESS = "SAVE_APP_SUCCESS";
export const SAVE_APP_FAIL = "SAVE_APP_FAILURE";

// App Config
export const APP_CONFIG_START = "APP_CONFIG_START";
export const APP_CONFIG_SUCCESS = "APP_CONFIG_SUCCESS";
export const APP_CONFIG_FAIL = "APP_CONFIG_FAILURE";
// Track Fields
export const TRACK_FIELDS_START = "TRACK_FIELDS_START";
// App Count
export const APP_COUNT_CHANGE = "APP_COUNT_CHANGE";
// Terms after qualified
export const SAVE_TERMS = "SAVE_TERMS";
// Header Analytics
export const HEADER_ANALYTICS_START = "HEADER_ANALYTICS_START";
export const HEADER_ANALYTICS_SUCCESS = "HEADER_ANALYTICS_SUCCESS";
export const HEADER_ANALYTICS_FAIL = "HEADER_ANALYTICS_FAILURE";
// DigiFi
export const DIGIFI_START = "DIGIFI_START";
export const DIGIFI_SUCCESS = "DIGIFI_SUCCESS";
export const DIGIFI_FAIL = "DIGIFI_FAILURE";
