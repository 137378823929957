import React, { Component } from "react";
import { validators } from "../../../utility/forms";
import { InputElement } from "../../UI";
import stepHandler from "../StepHandler";

import {
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	FormControl,
	Heading,
	SimpleGrid,
	Spacer,
	Text,
} from "@chakra-ui/react";

const trackedFields = ["firstName", "lastName", "email", "phone"];
class AutoLoan extends Component {
	constructor() {
		super();
		this.state = { loading: false };
	}
	componentDidMount() {
		this.props.trackFields("About You", trackedFields);
		window.scrollTo(0, 0);
	}

	render() {
		const nextButton = document.getElementById("next-button");

		const hasValidNames =
			validators.hasValue(this.props.form.lastName.value) &&
			validators.hasValue(this.props.form.firstName.value);

		//Check for on-screen errors
		let validCheck = false;
		const errors = document.getElementsByClassName("red");
		if (errors.length === 0) {
			validCheck = true;
		} else {
			validCheck = false;
		}

		//Form "Next" validation check
		nextButton.disabled = true;
		if (
			/^(([^<>()[\]\.,;:\s@\"+]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
				this.props.form.email.value,
			) &&
			/^\(\d{3}\)\s\d{3}-\d{4}/.test(this.props.form.phone.value) &&
			hasValidNames
		) {
			nextButton.disabled = false;
			this.props.form.loanPurpose.value = "AutoSecuredLoan";
		}

		return (
			<Box mt={10}>
				<Center>
					<Box maxW="600px">
						<Center>
							<Heading fontSize={"25px"} mb={14} id="AutoAboutYou" as="h1">
								Go farther with an auto-secured loan
							</Heading>
						</Center>
						<Text>
							Looking for a new loan? You may qualify by securing your loan with
							a vehicle. With an auto-secured loan your vehicle is provided as
							collateral, which could help you qualify for certain loan terms.
							For example, you may qualify for a larger loan amount. Republic
							Finance offers loans up to $25,000<sup>1</sup> in some states.
							Alternatively, you may also be eligible to refinance an existing
							auto loan you have with another lender and receive a lower
							interest rate or lower monthly payments.
						</Text>{" "}
						<br /> <br />
						<Text fontSize="2xl" as="b">
							Requirements
						</Text>
						<br /> <br />
						<Text>
							You must own the vehicle, either outright (meaning the vehicle’s
							title must be free of liens or any other encumbrances) or, in a
							refinance situation, the first lien holder must be able to be paid
							off with proceeds from the Republic Finance loan.
							<br /> <br />
							The vehicle must be worth sufficient value to support the loan.
							<br /> <br />
							You must maintain comprehensive and collision insurance coverage
							for the duration of the loan term.
							<br /> <br />
							An in-person appraisal is required. During the appraisal, a
							Republic Finance team member will inspect your vehicle’s condition
							to determine its value. This process helps us determine the loan
							amount we can offer.
							<sup>1</sup>
						</Text>
						<br /> <br />
						<Text fontSize="2xl" as="b">
							Get started
						</Text>
						<Text>
							{" "}
							<br />
							To get started, you will need to complete an application with your
							local Republic Finance branch. To help match you with your
							potential application, please fill in the information below and
							click “Continue” to be provided contact information for your local
							branch.
						</Text>{" "}
						<FormControl mt={10}>
							<SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ sm: 0, md: 5 }}>
								<Box>
									<InputElement
										formElement={this.props.form.firstName}
										onChange={(event) =>
											this.props.onChanged(event, "firstName")
										}
										onBlur={(event) => this.props.onBlurred(event, "firstName")}
									/>
								</Box>
								<Box>
									<InputElement
										formElement={this.props.form.lastName}
										onChange={(event) =>
											this.props.onChanged(event, "lastName")
										}
										onBlur={(event) => this.props.onBlurred(event, "lastName")}
									/>
								</Box>
								<Box>
									<InputElement
										formElement={this.props.form.suffix}
										onChange={(event) => this.props.onChanged(event, "suffix")}
										onBlur={(event) => this.props.onBlurred(event, "suffix")}
									/>
								</Box>
							</SimpleGrid>
							<Text mt={2} color="#777777">
								Enter your name as it appears on your state or US
								government-issued ID.
							</Text>
							<Box mt={6} maxWidth={350}>
								<InputElement
									formElement={this.props.form.email}
									onChange={(event) => this.props.onChanged(event, "email")}
									onBlur={(event) => this.props.onBlurred(event, "email")}
								/>
							</Box>
							<Box mt={2} maxWidth={250}>
								{" "}
								<InputElement
									formElement={this.props.form.phone}
									onChange={(event) => this.props.onChanged(event, "phone")}
									onBlur={(event) => this.props.onBlurred(event, "phone")}
								/>
							</Box>
						</FormControl>
						<Box mt={10} mb={10}>
							<Divider />
						</Box>
					</Box>
				</Center>
				<Container maxWidth={"600px"} mb={20}>
					<Flex>
						<Button
							id="back-button"
							onClick={() => {
								this.props.save();
								this.props.previous();
							}}
							bg="white"
							color="#2371a5"
							_hover={{ bg: "#3c99d3", color: "white" }}
							size="lg"
							border="1px"
							mt={10}
							p={4}
						>
							Back
						</Button>
						<Spacer />
						<Button
							id="next-button"
							onClick={() => {
								this.props.save();
								this.props.jumpToStep(5);

								this.setState({ loading: true });
							}}
							isLoading={this.state.loading}
							loadingText="Continue"
							bg="#2371a5"
							color="white"
							_hover={{ bg: "#3c99d3" }}
							size="lg"
							mt={10}
							p={4}
						>
							Continue
						</Button>{" "}
					</Flex>
				</Container>
				<Center>
					{" "}
					<Box maxW={"900px"}>
						<Text color="#777777" fontSize={"14px"}>
							{" "}
							<br />
							1. Loan terms (e.g., rates, origination fees and max. loan
							amounts) apply based on your state of residence and may vary
							depending on your creditworthiness. Optional voluntary products
							are available for purchase with any secured loan extended based on
							qualifying factors. Not all consumers will qualify. Secured loans
							have collateral and insurance requirements. The purchase of any
							required insurance from Republic Finance is optional. Auto-secured
							loans require a first lien on a vehicle that is titled in your
							name, with valid insurance. All refinance applications and
							closings must be completed with a branch employee and not on our
							website.
						</Text>{" "}
					</Box>{" "}
				</Center>
			</Box>
		);
	}
}

export default stepHandler(AutoLoan);
