import React, { Fragment } from "react";
import { OutboundLink } from "react-ga";

export function ElectronicConsentLabel() {
	return (
		<span style={{ fontWeight: "normal" }}>
			By checking this box and clicking "Next", I agree that I reviewed the{" "}
			<OutboundLink
				eventLabel="Clicked Esign Consent Link"
				rel="noopener noreferrer"
				target="_blank"
				to="/legal/esign-consent"
			>
				Consent for Electronic Signatures and Records
			</OutboundLink>
			; confirm that I can view and keep the electronic records described there;
			and consent to the use and terms of electronic records and signatures as
			described there. Additionally, by checking this box and clicking "Next", I
			agree that: (i)
			<span className="u-bold u-italic">
				{" "}
				I received, read, understood and consent to the{" "}
				<OutboundLink
					eventLabel="Clicked Terms of Use Link"
					rel="noopener noreferrer"
					target="_blank"
					to="/legal/terms-of-use"
				>
					Terms of Use
				</OutboundLink>{" "}
				(including the{" "}
				<OutboundLink
					eventLabel="Clicked Arbitration Agreement Link"
					rel="noopener noreferrer"
					target="_blank"
					to="/legal/terms-of-use#arbitration"
				>
					AGREEMENT TO ARBITRATION
				</OutboundLink>{" "}
				(Section 15) and{" "}
				<OutboundLink
					eventLabel="Clicked Jury Trial Waiver Link"
					rel="noopener noreferrer"
					target="_blank"
					to="/legal/terms-of-use#juryTrial"
				>
					JURY TRIAL WAIVER
				</OutboundLink>{" "}
				(Section 16) contained therein);{" "}
			</span>
			and (ii) I received and read Republic Finance's{" "}
			<OutboundLink
				eventLabel="Clicked Privacy Notice Link"
				rel="noopener noreferrer"
				target="_blank"
				to="/legal/privacy-policy"
			>
				Online Privacy Policy
			</OutboundLink>{" "}
			and{" "}
			<OutboundLink
				eventLabel="Clicked Privacy Policy Link"
				rel="noopener noreferrer"
				target="_blank"
				to="/legal/privacy-notice"
			>
				U.S. Consumer Privacy Notice.
			</OutboundLink>
		</span>
	);
}
