import ReactGA from "react-ga";

import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { appInsights } from "../../app-insights";
import axios from "../../axios-data";
import * as AnalyticsService from "../../services/analyticsService";
import * as ConfigService from "../../services/configService";
import * as DigifiService from "../../services/digifiService";
import * as actionTypes from "./actionTypes";

export const bootstrapApp = (appData) => async (dispatch) => {
	dispatch({ type: actionTypes.BOOTSTRAP_APP_START });
	try {
		const response = await Promise.all([
			ConfigService.loadConfiguration(),
			AnalyticsService.saveAnalytics(appData),
		]);
		const [configData, analyticsData] = response;
		dispatch({
			payload: { analyticsData, configData },
			type: actionTypes.BOOTSTRAP_APP_SUCCESS,
		});
		return response;
	} catch (err) {
		dispatch({ error: err, type: actionTypes.BOOTSTRAP_APP_FAILURE });
	}
};

export const saveAppSuccess = (app) => ({
	type: actionTypes.SAVE_APP_SUCCESS,
	payload: app,
});

export const saveAppFail = (error) => ({
	type: actionTypes.SAVE_APP_FAIL,
	error,
});

export const saveAppStart = () => ({
	type: actionTypes.SAVE_APP_START,
});

export const saveApp = (appData) => (dispatch) => {
	dispatch(saveAppStart());
	if (typeof appData.id === "undefined") {
		return axios
			.post("/app", appData)
			.then((response) => {
				const newApp = {
					...appData,
					id: response.data.id,
				};

				// Track Started Application Goal
				ReactGA.event({
					action: "PrequalStart",
					category: "Goal Event",
				});

				dispatch(saveAppSuccess(newApp));
			})
			.catch((error) => {
				dispatch(saveAppFail(error.response.data));
			});
	} else {
		const currentApp = {
			...appData,
			monthlyPayment: appData.monthlyPayment.replace(/[^0-9.]/g, ""),
			incomeAmount: appData.incomeAmount.replace(/[^0-9.]/g, ""),
			verifiableIncome: appData.verifiableIncome.replace(/[^0-9.]/g, ""),
		};
		return axios
			.put(`/app/${currentApp.id}`, currentApp)
			.then((response) => {
				const data = response.data;
				const url = process.env.REACT_APP_WEBSITE_URL;
				switch (data.currentStep) {
					case "Welcome":
						if (
							[data.city, data.state, data.zipcode].every((x) => x !== null)
						) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}
						break;
					case "Purpose":
						if ([data.loanAmount, data.loanPurpose].every((x) => x !== null)) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}
						break;
					case "About You":
						if (
							[
								data.city,
								data.dateOfBirth,
								data.email,
								data.firstName,
								data.lastName,
								data.phone,
								data.ssn,
								data.singleOrCoApp,
								data.street1,
								data.electronicConsent,
								data.telemarketingConsent,
							].every((x) => x !== null)
						) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}

						break;
					case "Co-Applicant":
						if (
							[
								data.coCity,
								data.coDateOfBirth,
								data.coEmail,
								data.coFirstName,
								data.coLastName,
								data.coPhone,
								data.coSsn,
								data.coStreet1,
								data.coElectronicConsent,
								data.coTelemarketingConsent,
							].every((x) => x !== null)
						) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}
						break;

					case "Finance":
						if (
							[
								data.rentOrOwn,
								data.monthlyPayment,
								data.numberOfDependents,
								data.incomeAmount,
								data.verifiableIncome,
							].every((x) => x !== null)
						) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}
						break;
					case "Consents":
						if (
							[data.telemarketingConsent, data.creditReportConsent].every(
								(x) => x !== null,
							)
						) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}
						break;
					case "Auto-Secured":
						if (
							[data.electronicConsent, data.telemarketingConsent].every(
								(x) => x !== null,
							)
						) {
							dispatch(saveAppSuccess(currentApp));
						} else {
							return axios.put(`/app/${currentApp.id}`, currentApp);
						}
						break;
				}
			})
			.catch((error) => {
				dispatch(saveAppFail(error.response.data));
			});
	}
};

export const trackFieldsStart = (fields) => ({
	type: actionTypes.TRACK_FIELDS_START,
	payload: fields,
});

export const onTrackFields = (dispatch) => {
	dispatch((fields) => trackFieldsStart(fields));
};

export const onApplicantCountChange = (e) => ({
	type: actionTypes.APP_COUNT_CHANGE,
	payload: e,
});

export const saveTerms = (terms) => ({
	type: actionTypes.SAVE_TERMS,
	payload: terms,
});

export const headerAnalyticsSuccess = (app) => ({
	type: actionTypes.HEADER_ANALYTICS_SUCCESS,
	payload: app,
});

export const headerAnalyticsFail = (error) => ({
	type: actionTypes.HEADER_ANALYTICS_FAIL,
	error,
});

export const headerAnalyticsStart = () => ({
	type: actionTypes.HEADER_ANALYTICS_START,
});

export const analyticsApp = (appData) => (dispatch) => {
	dispatch(headerAnalyticsStart());
	return AnalyticsService.saveAnalytics(appData)
		.then((response) => {
			dispatch(headerAnalyticsSuccess(response));
			return response;
		})
		.catch((error) => {
			dispatch(headerAnalyticsFail(error));
		});
};

export const digifiSuccess = () => ({
	type: actionTypes.DIGIFI_SUCCESS,
});

export const digifiFail = (error) => ({
	type: actionTypes.DIGIFI_FAIL,
	error,
});

export const digifiStart = () => ({
	type: actionTypes.DIGIFI_START,
});

export const digifiRequest = (appData, branch) => async (dispatch) => {
	dispatch(digifiStart());

	const redirectUrl = process.env.REACT_APP_APPLY_API_URL;
	const url = process.env.REACT_APP_WEBSITE_URL;
	const result = await DigifiService.startFunding(appData, branch);

	if (result.applicationId) {
		window.location.replace(
			`${redirectUrl}?applicationId=${result.applicationId}`,
		);
	} else {
		window.location.replace(`${url}apps/prequal/downtime-error`);
	}
};
