import { HStack, Link, Text, VStack } from "@chakra-ui/react";
import React from "react";

const navigation = [
	{
		group: "Contact Us",
		href: "/contact",
		navLinks: [],
	},
	{
		group: "Legal & Privacy",
		href: "/legal-privacy",
		navLinks: [],
	},
	{
		group: "Licenses & Disclosures",
		href: "/disclosures",
		navLinks: [],
	},
];

function Footer() {
	return (
		<VStack
			as="footer"
			w="full"
			mt="100px"
			pb="64px"
			bgColor="#F5F5F5"
			color="#636363"
			align="center"
			spacing={5}
		>
			<HStack
				spacing={{ base: 4, md: 8, lg: 12 }}
				mt={16}
				align="center"
				justify="center"
				w="full"
				px={4}
				maxW={{ base: "100%", md: "80%", lg: "70%" }}
				overflow="hidden"
			>
				{navigation.map((section) => (
					<Link
						key={section.group}
						href={section.href}
						color="#212121"
						fontWeight="bold"
						fontSize={{ base: "sm", md: "md", lg: "lg" }}
						textDecoration="underline"
						whiteSpace="nowrap"
						flexShrink={0}
					>
						{section.group}
					</Link>
				))}
			</HStack>

			<Text fontSize="sm" color="#636363" textAlign="center">
				© 2024 Republic Finance
			</Text>

			<Text fontSize="xs" textAlign="center" px="4" color="#636363">
				GA & OH: Republic Finance, LLC (NMLS # 182116). VA: Republic Finance,
				LLC is licensed by the Virginia State Corporation Commission (License
				No.: CFI-196).
			</Text>
		</VStack>
	);
}

export default Footer;
