import {
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	FormLabel,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "../../../axios-data";
import * as DigifiService from "../../../services/digifiService";
import retry from "../../../utility/retry";
import SendToTechnicalDifficulties from "../../../utility/techDifficulties";
import BranchSelect from "../../Branch/BranchSelect";
import stepHandler from "../StepHandler";

const TRANSITIONS = {
	INIT: 0,
	LOADING: 1,
	LOAD_FAILED: 2,
	LOAD_SUCCESS: 3,
	APPLIED: 4,
};
const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;

class Qualified extends Component {
	state = {
		app: null,
		success: false,
		applied: false,
		isLoading: false,
		status: TRANSITIONS.INIT,
		isQualified: false,
	};

	async componentDidMount() {
		window.scrollTo(0, 0);
		try {
			const { trackFields } = this.props;
			trackFields("Qualified", ["branch"]);

			const newApp = await DigifiService.markQualified(
				this.props.match.params.globalId,
			);
			newApp !== null
				? this.setState({
						app: newApp,
						success: true,
						status: TRANSITIONS.LOAD_SUCCESS,
					})
				: this.setState({
						app: newApp,
						success: false,
						status: TRANSITIONS.LOAD_FAILED,
					});

			if (newApp?.dateApplied && newApp?.branch) {
				this.setState({
					applied: true,
					status: TRANSITIONS.APPLIED,
					isQualified: true,
				});
			}
		} catch (e) {
			SendToTechnicalDifficulties(e);
		}
	}

	handleApply = async (event) => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			// consumer applied too many times in past twenty days?
			const response = await axios.get(
				`/app/TwentyDaysCheck/${this.state.app.id}`,
			);

			const newStatus = response.data.status;

			if (newStatus === true) {
				this.setState({ isLoading: false });

				window.location.replace(
					`${WEBSITE_URL}apps/prequal/declined-applied/${this.state.app.id}`,
				);
			} else {
				this.gdsAppliedCheck();
			}
		} catch (error) {
			this.setState({ isLoading: false });
			SendToTechnicalDifficulties(error);
		}
	};

	gdsAppliedCheck = () => {
		const { form } = this.props;
		let tcpa = false;

		if (this.state.app.marketingContactConsent) {
			tcpa = true;
		}

		axios
			.get(
				`/decisioning/apply/${this.state.app.id}/${
					form.branch.value.id ? form.branch.value.id : form.branch.value
				}/${tcpa}`,
			)
			.then((response) => {
				if (response.data.isOk) {
					this.setState({
						isQualified: true,
					});
				} else {
					SendToTechnicalDifficulties(response);
				}
			});
	};

	branchSelected = (branchNumber) => {
		this.state.app.branchNumber = branchNumber;
		this.props.onChanged(
			{ target: { type: "input", value: branchNumber } },
			"branch",
		);
	};

	updateBranchList = async () => {
		const newZip = document.getElementById("zipBranchUpdate").value;
		if (newZip.length === 5) {
			try {
				const response = await axios.get(`branch/zipcode/${newZip}`);
				if (
					response.data.branchNumber > 0 &&
					response.data.stateCode === this.state.app.state
				) {
					document.getElementById("zipError").innerHTML = "";
					const newApp = this.state.app;
					newApp.zipCode = newZip;
					this.setState({ app: newApp });
				} else {
					document.getElementById("zipError").innerHTML =
						`You must be in the state of ${this.state.app.state}.`;
				}
			} catch (error) {
				document.getElementById("zipError").innerHTML =
					"We don't recognize this ZIP code. Please enter a valid ZIP code.";
			}
		} else if (newZip.length < 5) {
			document.getElementById("zipError").innerHTML = "";
		}
	};

	render() {
		const { status } = this.state;
		const SENDING_MESSAGE = "Please wait while we send your information...";
		const LOADING_MESSAGE = "Please wait while we we load your application...";

		let branchSelectScreen = (
			<Box p={20}>
				<Center>
					<Text as="b" fontSize="3xl" mb={10}>
						{this.state.applied === true ? SENDING_MESSAGE : LOADING_MESSAGE}
					</Text>{" "}
				</Center>
			</Box>
		);

		if (status === TRANSITIONS.LOAD_FAILED) {
			branchSelectScreen = (
				<Box p={20}>
					<Center>
						<Text as="b" fontSize="3xl" mb={10}>
							Sorry, we could not locate a record of that pre-qualification.
						</Text>{" "}
					</Center>
					<Center>
						<Box>
							<Button
								bg="#2371a5"
								color="white"
								_hover={{ bg: "#3c99d3" }}
								size="lg"
								mt={10}
								p={8}
								onClick={() => this.props.history.push("/")}
							>
								{" "}
								Get Pre-Qualified
							</Button>
						</Box>
					</Center>
				</Box>
			);
		} else if (status === TRANSITIONS.LOAD_SUCCESS) {
			branchSelectScreen = (
				<Box p={20}>
					<Box p={5}>
						{this.state.isQualified == false ? (
							<Center>
								<Text as="b" fontSize="3xl" mb={10}>
									Choose a local branch
								</Text>{" "}
							</Center>
						) : null}

						<Center>
							<Box maxW={"600px"} minW={"300px"}>
								{this.state.isQualified == false ? (
									<Box>
										<Text>
											Your loan application will be submitted to your selected
											branch below. If this is not your preferred location,
											enter a different ZIP code to select from a list of
											branches in that area.
										</Text>
										<Center>
											<Box w={"141px"} mt={10} mb={10}>
												<FormLabel>ZIP Code*</FormLabel>
												<input
													className="form-control"
													type="text"
													maxLength="5"
													autoComplete="postal-code"
													id="zipBranchUpdate"
													onKeyDown={(e) => {
														if (e.keyCode === 13) {
															e.preventDefault();
														}
													}}
													placeholder={null}
													onChange={this.updateBranchList}
												/>
											</Box>
										</Center>
									</Box>
								) : null}

								<BranchSelect
									marketingContactConsent={
										this.state.app.marketingContactConsent
									}
									selectedBranch={this.state.app.branchNumber}
									stateCode={this.state.app.state}
									zipCode={this.state.app.zipCode}
									onBranchSelected={this.branchSelected}
									isQualified={this.state.isQualified}
								/>

								{this.state.isQualified == false ? (
									<Box>
										<Box m={5}>
											<Divider />
										</Box>
										<Container maxWidth={"600px"}>
											<Flex>
												<Spacer />
												<Button
													disabled={this.state.isLoading}
													onClick={this.handleApply}
													bg="#2371a5"
													color="white"
													_hover={{ bg: "#3c99d3" }}
													size="lg"
													mt={10}
													p={8}
												>
													Continue
												</Button>
											</Flex>
										</Container>
									</Box>
								) : null}
							</Box>
						</Center>
					</Box>
				</Box>
			);
		} else if (status === TRANSITIONS.LOAD_SUCCESS && !this.props.isQualified) {
		}
		return branchSelectScreen;
	}
}

const mapStateToProps = (state) => ({
	terms: state.terms,
	app: state.app,
});

export default connect(mapStateToProps)(stepHandler(Qualified));
