import React, { Component, createRef } from "react";
import { FaEyeSlash, FaRegEye } from "react-icons/fa";
import { RiLock2Line } from "react-icons/ri";
// import { ReCaptcha } from "react-recaptcha-v3";
import { validators } from "../../../utility/forms";
import { InputElement } from "../../UI";
import stepHandler from "../StepHandler";
import { SingleOrCoApp } from "../Steps";

import {
	Box,
	Button,
	Center,
	Container,
	Divider,
	Flex,
	FormControl,
	HStack,
	Heading,
	InputGroup,
	SimpleGrid,
	Spacer,
	Text,
} from "@chakra-ui/react";
const trackedFields = [
	"firstName",
	"lastName",
	"suffix",
	"street1",
	"street2",
	"city",
	"state",
	"zipCode",
	"ssn",
	"dateOfBirth",
	"email",
	"phone",
];

class AboutYou extends Component {
	constructor() {
		super();
		this.state = { loading: false, hidden: true };
	}
	cityRef = createRef();
	streetRef = createRef();

	previousZoneStyles = null;

	state = {
		url: process.env.REACT_APP_WEBSITE_URL,
		// recaptchaInit: false,
		// recaptchaVerified: false,
	};

	componentDidMount() {
		this.props.trackFields("About You", trackedFields);
		window.scrollTo(0, 0);

		document.getElementById("ssn").type = "password";
	}

	toggleHidden = () => {
		this.setState({ hidden: !this.state.hidden });

		if (document.getElementById("ssn").type === "password") {
			document.getElementById("ssn").type = "text";
		} else {
			document.getElementById("ssn").type = "password";
		}
	};

	render() {
		const nextButton = document.getElementById("next-button");

		const hasValidNames =
			validators.hasValue(this.props.form.lastName.value) &&
			validators.hasValue(this.props.form.firstName.value);
		const hasValidDob =
			this.props.form.dateOfBirth.value &&
			validators.validateMinimumAge(
				this.props.form.dateOfBirth.value,
				this.props.config.minimumAge,
			).isValid;

		//Check for on-screen errors
		let validCheck = false;
		const errors = document.getElementsByClassName("red");
		if (errors.length === 0) {
			validCheck = true;
		} else {
			validCheck = false;
		}

		//Form "Next" validation check
		nextButton.disabled = true;
		if (
			/^\d{3}-\d{2}-\d{4}$/.test(this.props.form.ssn.value) &&
			/^(([^<>()[\]\.,;:\s@\"+]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
				this.props.form.email.value,
			) &&
			/^\(\d{3}\)\s\d{3}-\d{4}/.test(this.props.form.phone.value) &&
			hasValidNames &&
			/^\d{5}$/.test(this.props.form.zipCode.value) &&
			this.props.form.street1.value !== "" &&
			this.props.form.city.value !== "" &&
			this.props.form.state.value !== "" &&
			this.props.form.singleOrCoApp.value !== "" &&
			hasValidDob
		) {
			nextButton.disabled = false;
		}

		return (
			<Box mt={10} mb={20}>
				<Center>
					<Box maxW="600px">
						<Center>
							<Heading size="md" mb={14}>
								Let’s get some information about you
							</Heading>
						</Center>

						<Text fontSize="18px" as="b">
							ABOUT YOU
						</Text>
						<FormControl mt={10}>
							<SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ sm: 0, md: 5 }}>
								<Box>
									<InputElement
										formElement={this.props.form.firstName}
										onChange={(event) =>
											this.props.onChanged(event, "firstName")
										}
										onBlur={(event) => this.props.onBlurred(event, "firstName")}
									/>
								</Box>
								<Box>
									<InputElement
										formElement={this.props.form.lastName}
										onChange={(event) =>
											this.props.onChanged(event, "lastName")
										}
										onBlur={(event) => this.props.onBlurred(event, "lastName")}
									/>
								</Box>

								<Box>
									<InputElement
										formElement={this.props.form.suffix}
										onChange={(event) => this.props.onChanged(event, "suffix")}
										onBlur={(event) => this.props.onBlurred(event, "suffix")}
									/>
								</Box>
							</SimpleGrid>
							<Text mt={2} color="#777777">
								Enter your name as it appears on your state or US
								government-issued ID.
							</Text>

							<Box mt={6} maxWidth={250}>
								<InputElement
									formElement={this.props.form.email}
									onChange={(event) => this.props.onChanged(event, "email")}
									onBlur={(event) => this.props.onBlurred(event, "email")}
								/>
							</Box>
							<Box maxWidth={250} mt={5}>
								{" "}
								<InputElement
									formElement={this.props.form.phone}
									onChange={(event) => this.props.onChanged(event, "phone")}
									onBlur={(event) => this.props.onBlurred(event, "phone")}
								/>
							</Box>
							<Text mt={2} color="#777777">
								You can choose your communication preferences later.
							</Text>
							<Box mt={6} maxWidth={250}>
								<InputElement
									formElement={this.props.form.dateOfBirth}
									onChange={(event) =>
										this.props.onChanged(event, "dateOfBirth")
									}
									onBlur={(event) => this.props.onBlurred(event, "dateOfBirth")}
								/>
							</Box>

							<Container mt={25} mb={25} maxW="600px" bg="#F5F5F5" p={6}>
								<Box p={5}>
									<Text fontSize="sm">
										We’ll use this to verify your identity and determine your
										offer. Remember, this{" "}
										<span style={{ fontWeight: "bold" }}>
											doesn’t impact your credit score!
										</span>
									</Text>
									<Text fontSize="sm" mb={3} mt={4}>
										Social Security Number
									</Text>

									<Box>
										{" "}
										<InputGroup size="md">
											{" "}
											<Box minWidth={270}>
												<InputElement
													formElement={this.props.form.ssn}
													onChange={(event) =>
														this.props.onChanged(event, "ssn")
													}
													onBlur={(event) => this.props.onBlurred(event, "ssn")}
												/>{" "}
											</Box>
											<Box>
												{this.state.hidden ? (
													<Box m={4} ml={-7} onClick={this.toggleHidden}>
														<FaEyeSlash />
													</Box>
												) : (
													<Box m={4} ml={-7} onClick={this.toggleHidden}>
														<FaRegEye />
													</Box>
												)}
											</Box>
										</InputGroup>
									</Box>

									<Box mt={5}>
										<Divider mb={7} />
										<HStack>
											{" "}
											<RiLock2Line />
											<Text fontSize="sm">
												Your information is safe and secure
											</Text>
										</HStack>
									</Box>
								</Box>
							</Container>
							<Text fontSize="18px" as="b">
								YOUR MAILING ADDRESS
							</Text>
							<SimpleGrid
								mt={14}
								columns={{ sm: 1, md: 2 }}
								gap={{ sm: 20, md: 40 }}
							>
								<Box minW={300}>
									<InputElement
										domRef={this.streetRef}
										formElement={this.props.form.street1}
										onChange={(event) => this.props.onChanged(event, "street1")}
										onBlur={(event) => this.props.onBlurred(event, "street1")}
									/>
								</Box>
								<Box>
									<InputElement
										formElement={this.props.form.street2}
										onChange={(event) => this.props.onChanged(event, "street2")}
										onBlur={(event) => this.props.onBlurred(event, "street2")}
									/>
								</Box>
							</SimpleGrid>
							<SimpleGrid columns={{ sm: 1, md: 3 }} gap={5} mt={5}>
								<Box>
									<InputElement
										domRef={this.cityRef}
										formElement={this.props.form.city}
										onChange={(event) => this.props.onChanged(event, "city")}
										onBlur={(event) => this.props.onBlurred(event, "city")}
									/>
								</Box>
								<Box>
									<InputElement
										readOnly
										formElement={this.props.form.state}
										onChange={(event) => this.props.onChanged(event, "state")}
										onBlur={(event) => this.props.onBlurred(event, "state")}
									/>
								</Box>

								<Box>
									<InputElement
										className="zip"
										readOnly
										formElement={this.props.form.zipCode}
										onChange={(event) => this.props.onChanged(event, "zipCode")}
										onBlur={(event) => this.props.onBlurred(event, "zipCode")}
										style={{ backgroundColor: "#E9EcEF" }}
									/>
								</Box>
							</SimpleGrid>
						</FormControl>

						{/* <ReCaptcha
							sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
							action="aboutLoad"
							size="invisible"
							verifyCallback={this.verifyCallback}
						/> */}
						<SingleOrCoApp {...this.props} />
						<Box m={5}>
							<Divider />
						</Box>
					</Box>
				</Center>
				<Container maxWidth={"600px"}>
					<Flex>
						<Button
							id="back-button"
							onClick={() => {
								this.props.save();
								this.props.previous();
							}}
							bg="white"
							color="#2371a5"
							_hover={{ bg: "#3c99d3", color: "white" }}
							size="lg"
							border="1px"
							mt={10}
							p={4}
						>
							Back
						</Button>
						<Spacer />
						<Button
							id="next-button"
							onClick={() => {
								this.props.next();
								this.setState({ loading: true });
							}}
							isLoading={this.state.loading}
							loadingText="Continue"
							bg="#2371a5"
							color="white"
							_hover={{ bg: "#3c99d3" }}
							size="lg"
							mt={10}
							p={4}
						>
							Continue
						</Button>{" "}
					</Flex>
				</Container>
			</Box>
		);
	}
}

export default stepHandler(AboutYou);
