import {
	Box,
	Center,
	HStack,
	Heading,
	Link,
	Skeleton,
	Stack,
	Text,
} from "@chakra-ui/react";
import React, { Component } from "react";
import { BsFillTelephoneFill } from "react-icons/bs";
import axios from "../../../axios-data";
import stepHandler from "../StepHandler.js";

class AutoLoanReview extends Component {
	constructor() {
		super();
		this.state = { branch: null, branchError: null };
	}
	componentDidMount() {
		window.scrollTo(0, 0);

		try {
			axios.get(`/branch/near/${this.props.app.zipCode}`).then((response) => {
				const closestBranch = response.data.map((item) => ({
					...item,
				}));
				this.setState({ branch: closestBranch[0] });
			});
		} catch (e) {
			this.setState({ branchError: true });
		}
	}

	render() {
		return (
			<Box mt={10} mb={20}>
				<Center>
					<Box maxW="600px">
						<Center>
							<Heading fontSize={"25px"} as="h1">
								Call your local branch for next steps!
							</Heading>
						</Center>
						<Box p={10}>
							{this.state.branchError ? (
								<Text>
									The next step for you to start an auto-secured loan
									application is to contact your local branch, either in person
									or over the phone.
								</Text>
							) : (
								<Box>
									<Text>
										The next step for you to start an auto-secured loan
										application is to contact your local branch, either in
										person or over the phone. You can find your local branch’s
										phone number and address below.
									</Text>
								</Box>
							)}
						</Box>
						<br />
						<Center>
							<Box width={"350px"} bg="#F5F5F5" p={10} borderRadius="6px">
								{" "}
								<Box pl={5} mt={5}>
									{this.state.branchError ? (
										<Box width={"300px"} bg="#F5F5F5" mb={5} borderRadius="6px">
											{" "}
											<HStack>
												<Box mr={6} mt={2} ml={10}>
													<BsFillTelephoneFill size="18px" color="#3C99D4" />
												</Box>

												<Box>
													<Link href={"tel: 866-356-1320"}>
														{" "}
														<Text as="b" fontSize="24px" color="#3C99D4">
															{" "}
															866-356-1320
														</Text>{" "}
													</Link>
												</Box>
											</HStack>
										</Box>
									) : !this.state.branch?.name ? (
										<Stack>
											<Skeleton height="20px" /> <Skeleton height="20px" />{" "}
											<Skeleton height="20px" />
										</Stack>
									) : (
										<>
											{" "}
											<Text fontSize="2xl" as="b">
												YOUR LOCAL BRANCH
											</Text>
											<br /> <br />
											<Text>{this.state.branch?.address} </Text>
											<Text>
												{this.state.branch?.city}, {this.state.branch?.state}{" "}
												{this.state.branch?.zipCode}{" "}
											</Text>
											<Text>{this.state.branch?.hoursOfOperation}</Text>
											<HStack m={2}>
												<Box mt={1}>
													<BsFillTelephoneFill color="#3C99D4" />
												</Box>

												<Link href={"tel:" + this.state.branch?.phone}>
													{" "}
													<Box as="b">
														<Text as="u" color="#3C99D4">
															{this.state.branch?.phone}
														</Text>{" "}
													</Box>
												</Link>
											</HStack>
										</>
									)}
								</Box>
							</Box>
						</Center>
					</Box>
				</Center>
			</Box>
		);
	}
}

export default stepHandler(AutoLoanReview);
