import axios from "../axios-data";

export const markQualified = async (globalId) => {
	try {
		const response = await axios.get(`/app/MarkQualified/${globalId}`);
		if (response.data.qualified === "false") {
			return null;
		} else {
			return response.data.app;
		}
	} catch (ex) {
		console.log(ex);
		throw ex;
	}
};

export const startFunding = async (appData, branch) => {
	try {
		const response = await axios.post(
			`Funding/StartFunding`,
			{
				verify_session_id: appData.headerAnalyticsId,
				web_session_id: appData.id,
				transaction_id: appData.id,
				branch_number: branch.id,
				branch_name: branch.name,
				branch_address: branch.address,
				branch_city: branch.city,
				branch_state: branch.state,
				branch_zip: branch.zipCode,
				branch_phone: branch.phone,
				branch_hours: branch.hoursOfOperation,
			},
			{ headers: { "content-type": "application/json" } },
		);
		return response.data;
	} catch (ex) {
		console.log(ex);
		throw ex;
	}
};
