const states = [
	{
		displayValueLong: "Alabama",
		displayValue: "AL",
		value: "AL",
	},
	{
		displayValueLong: "Alaska",
		displayValue: "AK",
		value: "AK",
	},
	{
		displayValueLong: "American Samoa",
		displayValue: "AS",
		value: "AS",
	},
	{
		displayValueLong: "Arizona",
		displayValue: "AZ",
		value: "AZ",
	},
	{
		displayValueLong: "Arkansas",
		displayValue: "AR",
		value: "AR",
	},
	{
		displayValueLong: "California",
		displayValue: "CA",
		value: "CA",
	},
	{
		displayValueLong: "Colorado",
		displayValue: "CO",
		value: "CO",
	},
	{
		displayValueLong: "Connecticut",
		displayValue: "CT",
		value: "CT",
	},
	{
		displayValueLong: "Delaware",
		displayValue: "DE",
		value: "DE",
	},
	{
		displayValueLong: "District Of Columbia",
		displayValue: "DC",
		value: "DC",
	},
	{
		displayValueLong: "Florida",
		displayValue: "FL",
		value: "FL",
	},
	{
		displayValueLong: "Georgia",
		displayValue: "GA",
		value: "GA",
	},
	{
		displayValueLong: "Guam",
		displayValue: "GU",
		value: "GU",
	},
	{
		displayValueLong: "Hawaii",
		displayValue: "HI",
		value: "HI",
	},
	{
		displayValueLong: "Idaho",
		displayValue: "ID",
		value: "ID",
	},
	{
		displayValueLong: "Illinois",
		displayValue: "IL",
		value: "IL",
	},
	{
		displayValueLong: "Indiana",
		displayValue: "IN",
		value: "IN",
	},
	{
		displayValueLong: "Iowa",
		displayValue: "IA",
		value: "IA",
	},
	{
		displayValueLong: "Kansas",
		displayValue: "KS",
		value: "KS",
	},
	{
		displayValueLong: "Kentucky",
		displayValue: "KY",
		value: "KY",
	},
	{
		displayValueLong: "Louisiana",
		displayValue: "LA",
		value: "LA",
	},
	{
		displayValueLong: "Maine",
		displayValue: "ME",
		value: "ME",
	},
	{
		displayValueLong: "Maryland",
		displayValue: "MD",
		value: "MD",
	},
	{
		displayValueLong: "Massachusetts",
		displayValue: "MA",
		value: "MA",
	},
	{
		displayValueLong: "Michigan",
		displayValue: "MI",
		value: "MI",
	},
	{
		displayValueLong: "Minnesota",
		displayValue: "MN",
		value: "MN",
	},
	{
		displayValueLong: "Mississippi",
		displayValue: "MS",
		value: "MS",
	},
	{
		displayValueLong: "Missouri",
		displayValue: "MO",
		value: "MO",
	},
	{
		displayValueLong: "Montana",
		displayValue: "MT",
		value: "MT",
	},
	{
		displayValueLong: "Nebraska",
		displayValue: "NE",
		value: "NE",
	},
	{
		displayValueLong: "Nevada",
		displayValue: "NV",
		value: "NV",
	},
	{
		displayValueLong: "New Hampshire",
		displayValue: "NH",
		value: "NH",
	},
	{
		displayValueLong: "New Jersey",
		displayValue: "NJ",
		value: "NJ",
	},
	{
		displayValueLong: "New Mexico",
		displayValue: "NM",
		value: "NM",
	},
	{
		displayValueLong: "New York",
		displayValue: "NY",
		value: "NY",
	},
	{
		displayValueLong: "North Carolina",
		displayValue: "NC",
		value: "NC",
	},
	{
		displayValueLong: "North Dakota",
		displayValue: "ND",
		value: "ND",
	},
	{
		displayValueLong: "Ohio",
		displayValue: "OH",
		value: "OH",
	},
	{
		displayValueLong: "Oklahoma",
		displayValue: "OK",
		value: "OK",
	},
	{
		displayValueLong: "Oregon",
		displayValue: "OR",
		value: "OR",
	},
	{
		displayValueLong: "Pennsylvania",
		displayValue: "PA",
		value: "PA",
	},
	{
		displayValueLong: "Rhode Island",
		displayValue: "RI",
		value: "RI",
	},
	{
		displayValueLong: "South Carolina",
		displayValue: "SC",
		value: "SC",
	},
	{
		displayValueLong: "South Dakota",
		displayValue: "SD",
		value: "SD",
	},
	{
		displayValueLong: "Tennessee",
		displayValue: "TN",
		value: "TN",
	},
	{
		displayValueLong: "Texas",
		displayValue: "TX",
		value: "TX",
	},
	{
		displayValueLong: "Utah",
		displayValue: "UT",
		value: "UT",
	},
	{
		displayValueLong: "Vermont",
		displayValue: "VT",
		value: "VT",
	},
	{
		displayValueLong: "Virginia",
		displayValue: "VA",
		value: "VA",
	},
	{
		displayValueLong: "Washington",
		displayValue: "WA",
		value: "WA",
	},
	{
		displayValueLong: "West Virginia",
		displayValue: "WV",
		value: "WV",
	},
	{
		displayValueLong: "Wisconsin",
		displayValue: "WI",
		value: "WI",
	},
	{
		displayValueLong: "Wyoming",
		displayValue: "WY",
		value: "WY",
	},
];

export default states;
