import { Container, HStack, Image, Link } from "@chakra-ui/react";
import React from "react";

function Header() {
	const logoUrl = `${process.env.PUBLIC_URL}/assets/images/rflogo.png`;

	return (
		<HStack
			as="header"
			backgroundColor="gray.50"
			h="90px"
			zIndex="sticky"
			alignItems="center"
		>
			<Container maxW="80em" display="flex" justifyContent="left">
				<Link href="/">
					<Image
						h="80px"
						alt="Republic Finance Logo"
						src={logoUrl}
						objectFit="contain"
					/>
				</Link>
			</Container>
		</HStack>
	);
}

export default Header;
