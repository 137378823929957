import { Container, Stack, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import MaskedInput from "react-text-mask";
import store from "../../../store";

const input = (props) => {
	let inputElement = null;
	const {
		blurred,
		changed,
		content,
		coContent,
		domRef,
		elementConfig,
		elementType,
		label,
		readOnly,
		touched,
		validation,
		validationResults,
		value,
		inputMode,
		disabled,
	} = props;

	const inputClasses =
		elementConfig !== undefined &&
		elementConfig !== null &&
		elementConfig.unwrap === "true"
			? []
			: ["form-control"];

	if (
		validationResults !== undefined &&
		!validationResults.isValid &&
		touched &&
		validationResults.validationType === undefined
	) {
		inputClasses.push("Invalid");
	}

	switch (elementType) {
		case "zipCode":
			inputElement = (
				<input
					className={inputClasses.join(" ")}
					value={value}
					onChange={changed}
					onBlur={blurred}
					readOnly={readOnly}
					ref={domRef}
					id={elementConfig.id}
					minLength={elementConfig.minLength}
					maxLength={elementConfig.maxLength}
					autoComplete={elementConfig.autoComplete}
					onKeyDown={(e) => {
						if (e.code.toLowerCase === "enter") {
							e.preventDefault();
						}
					}}
					onInput={(e) => {
						e.target.value = e.target.value;
						e.target.value = e.target.value
							.replace(/[^0-9]/g, "")
							.replace(/(\..*)\./g, "$1");
					}}
					inputMode={inputMode}
				/>
			);
			break;
		case "textarea":
			inputElement = (
				<textarea
					className={inputClasses.join(" ")}
					{...elementConfig}
					ref={domRef}
					value={value}
					onChange={changed}
					onBlur={blurred}
					readOnly={readOnly}
				/>
			);
			break;
		case "select":
		case "dependents":
			inputElement = (
				<select
					{...elementConfig}
					className={inputClasses.join(" ")}
					ref={domRef}
					value={value}
					onChange={changed}
					onBlur={blurred}
					readOnly={readOnly}
				>
					<option defaultValue="" value="" disabled>
						Select One...
					</option>
					{elementConfig.options.map((option) => (
						<option key={option.value} value={option.value}>
							{option.displayValue}
						</option>
					))}
				</select>
			);
			break;
		case "loanAmount":
			inputElement = (
				<select
					{...elementConfig}
					className={inputClasses.join(" ")}
					ref={domRef}
					value={value}
					onChange={changed}
					onBlur={blurred}
					readOnly={readOnly}
					id={elementConfig.id}
				>
					{elementConfig.options.map((option) => (
						<option
							key={option.value}
							value={option.value}
							disabled={option.disabled}
						>
							{option.displayValue}
						</option>
					))}
				</select>
			);
			break;
		case "radio":
			inputElement = (
				<div className={inputClasses.join(" ")}>
					{elementConfig.options.map((option, key) => (
						<label className={elementConfig.radioClass} key={key}>
							<input
								type="radio"
								value={option.value}
								ref={domRef}
								checked={value === option.value}
								onChange={changed}
								readOnly={readOnly}
							/>
							{option.displayValue}
						</label>
					))}
				</div>
			);
			break;
		case "checkbox":
		case "consent":
			if (value !== "true" && value !== "false") {
				inputElement = (
					<div className="checkbox-flex">
						<input
							type="checkbox"
							value={value}
							ref={domRef}
							onChange={changed}
							readOnly={readOnly}
							checked={value}
						/>
						<label className="consents-label">{label}</label>
					</div>
				);
			} else {
				inputElement = (
					<div className="checkbox-flex">
						<input
							ref={domRef}
							type="checkbox"
							value={value}
							onChange={changed}
							readOnly={readOnly}
							checked={JSON.parse(value)}
						/>
						<label className="consents-label">{label}</label>
					</div>
				);
			}
			break;
		case "creditReportConsent":
			if (store.getState().app.singleOrCoApp === "Co-Applicant") {
				inputElement = (
					<div className="checkbox-flex">
						<input
							ref={domRef}
							type="checkbox"
							value={value}
							onChange={changed}
							readOnly={readOnly}
							checked={value}
						/>
						<label className="consents-label">{coContent}</label>
					</div>
				);
			} else {
				inputElement = (
					<div className="checkbox-flex">
						<input
							ref={domRef}
							type="checkbox"
							value={value}
							onChange={changed}
							readOnly={readOnly}
							checked={value}
						/>
						<label className="consents-label">{content}</label>
					</div>
				);
			}
			break;
		case "firstname":
			inputElement = (
				<MaskedInput
					mask={elementConfig.mask}
					guide={elementConfig.guide}
					pipe={elementConfig.pipe}
					keepCharPositions={elementConfig.keepCharPositions}
					className={inputClasses.join(" ")}
					value={value}
					onChange={changed}
					onBlur={(e) => {
						if (e.target.value.slice(-1) == " ") {
							e.target.value = e.target.value.trim();
							changed(e);
						}
						blurred(e);
					}}
					onKeyDown={elementConfig.onKeyDown}
					readOnly={readOnly}
					id={elementConfig.id}
					minLength={elementConfig.minLength}
					maxLength={elementConfig.maxLength}
					autoComplete={elementConfig.autoComplete}
					onInput={(e) => {
						e.target.value = e.target.value
							.replace(/[^0-9a-zA-Z-'"_ ]/g, "")
							.replace(/(\..*)\./g, "$1");
					}}
					inputMode={inputMode}
				/>
			);
			break;
		case "email":
			inputElement = (
				<input
					className={inputClasses.join(" ")}
					defaultValue={value}
					onChange={changed}
					onBlur={blurred}
					onKeyDown={elementConfig.onKeyDown}
					readOnly={readOnly}
					id={elementConfig.id}
					minLength={elementConfig.minLength}
					maxLength={elementConfig.maxLength}
					ref={domRef}
					autoComplete={elementConfig.autoComplete}
					onInput={(e) => {
						e.target.value = e.target.value
							.replace(/[^0-9a-zA-Z .*()!#$%&'*+-/=?^_`{|}~@_ ]/g, "")
							.replace(/^\s+/, "");
					}}
					inputMode={inputMode}
				/>
			);
			break;
		default:
			if (elementConfig.mask) {
				inputElement = (
					<MaskedInput
						mask={elementConfig.mask}
						guide={elementConfig.guide}
						pipe={elementConfig.pipe}
						keepCharPositions={elementConfig.keepCharPositions}
						className={inputClasses.join(" ")}
						value={value}
						onChange={changed}
						onBlur={blurred}
						onKeyDown={elementConfig.onKeyDown}
						readOnly={readOnly}
						id={elementConfig.id}
						minLength={elementConfig.minLength}
						maxLength={elementConfig.maxLength}
						autoComplete={elementConfig.autoComplete}
						onInput={(e) => {
							e.target.value = e.target.value
								.replace(/[^0-9a-zA-Z-'"_ ]/g, "")
								.replace(/(\..*)\./g, "$1");
						}}
						inputMode={inputMode}
					/>
				);
			} else {
				inputElement = (
					<input
						className={inputClasses.join(" ")}
						defaultValue={value}
						onChange={changed}
						onBlur={blurred}
						onKeyDown={elementConfig.onKeyDown}
						readOnly={readOnly}
						id={elementConfig.id}
						minLength={elementConfig.minLength}
						maxLength={elementConfig.maxLength}
						ref={domRef}
						autoComplete={elementConfig.autoComplete}
						onInput={(e) => {
							e.target.value = e.target.value
								.replace(/[^0-9a-zA-Z.()!#$%&'*+-/=?^_`{|}~@_ ]/g, "")
								.replace(/(\..*)\./g, "$1");
						}}
						inputMode={inputMode}
					/>
				);
			}
	}

	const hasErrors =
		validationResults !== undefined &&
		validationResults.validationErrors !== undefined &&
		validationResults.validationErrors.length > 0;

	const hasSuccess =
		validationResults !== undefined &&
		validationResults.validationSuccess !== undefined &&
		validationResults.validationSuccess.length > 0;

	const errors = hasErrors && !validationResults.validationLink && (
		<span
			className={
				validationResults.validationType
					? validationResults.validationType
					: "red"
			}
		>
			{validationResults.validationErrors}
		</span>
	);

	const errorsWithLink = hasErrors && validationResults.validationLink && (
		<span
			className={
				validationResults.validationType
					? validationResults.validationType
					: "black"
			}
		>
			{validationResults.validationErrors}
			<a href={validationResults.validationLink}>loan check page. </a>
		</span>
	);
	const success = hasSuccess && (
		<span className="green" id="validResult">
			{/* {validationResults.validationSuccess} */}
		</span>
	);

	let form = (
		<div className="form-group">
			<div>
				{label}
				{validation !== undefined &&
					validation.required !== undefined &&
					validation.required === false && (
						<span className="optional">(optional)</span>
					)}
			</div>
			{inputElement}
			{errors}
			{errorsWithLink}
			{success}
		</div>
	);
	if (elementType === "checkbox") {
		form = <div className="form-group">{inputElement}</div>;
	}
	if (elementType === "radio") {
		form = (
			<div className="form-group">
				<label>{label}</label>
				{inputElement}
				{errors}
				{errorsWithLink}
			</div>
		);
	}

	//Custom Element Types (SSN)
	if (elementType === "ssn") {
		form = (
			<div className="form-group">
				<div className="ssn-content"></div>
				{inputElement}
				{errors}
				{errorsWithLink}
			</div>
		);
	}

	// Custom Element Types (Consents)
	if (elementType === "consent") {
		form = (
			<div className="form-group">
				<div className="checkbox-flex">
					<label style={{ fontWeight: 100 }}>
						{inputElement}
						{errors}
						{errorsWithLink}
					</label>
				</div>
			</div>
		);
	}

	if (elementType === "creditReportConsent") {
		form = (
			<div className="form-group">
				<div className="checkbox-flex">
					<label style={{ fontWeight: 100 }}>
						{inputElement}
						<br />
						{errors}
						{errorsWithLink}
					</label>
				</div>
			</div>
		);
	}

	return <Fragment>{form}</Fragment>;
};

export default input;
