import {
	Box,
	Button,
	Center,
	Checkbox,
	Container,
	Divider,
	Flex,
	Grid,
	GridItem,
	Heading,
	Link,
	Progress,
	Spacer,
	Text,
} from "@chakra-ui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import React, { Component } from "react";
import { connect } from "react-redux";
import { appInsights } from "../../../app-insights";
import axios from "../../../axios-data";
import store from "../../../store";
import * as actions from "../../../store/actions/actions";
import stepHandler from "../StepHandler";

const SUBMIT_STATUS = {
	APPROVED: "Approved",
	COUNTER_OFFER: "Counter Offer",
	DECLINED: "Declined",
	ERROR: "ERROR",
	NO_MATCH: "No Match",
};

const goToErrorPage = (url) => {
	appInsights.trackEvent({
		name: "GDS Submit Error Redirect",
		severityLevel: SeverityLevel.Error,
	});

	window.location.replace(`${url}apps/prequal/downtime-error`);
};

class Consents extends Component {
	state = {
		tcpaFlag: true,
		url: process.env.REACT_APP_WEBSITE_URL,
		branch: null,
		gdsRequestInProcess: false,
	};

	componentDidMount() {
		this.props.trackFields("Consents", ["creditReportConsent"]);
		window.scrollTo(0, 0);
		if (this.props.app.telemarketingConsent !== null) {
			this.setState({ tcpaFlag: false });
		}
		axios.get(`/branch/near/${this.props.app.zipCode}`).then((response) => {
			const newBranch = response.data.map((item) => ({
				...item,
			}));
			this.setState({ branch: newBranch[0] });
		});
		if (this.props.appError?.EConsent) {
			appInsights.trackEvent({
				name: `${this.props.appError.EConsent[0]}`,
				severityLevel: SeverityLevel.Error,
			});
			window.location.replace(`${this.state.url}apps/prequal/downtime-error`);
		}
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.app.telemarketingConsent !== this.props.app.telemarketingConsent
		) {
			if (this.props.app.telemarketingConsent !== null) {
				this.setState({ tcpaFlag: false });
			}
		}
	}

	gdsRequest = async () => {
		if (this.state.gdsRequestInProcess === false) {
			this.setState({ gdsRequestInProcess: true });
		}

		try {
			await this.props.save();

			const {
				data: { newStatus },
			} = await axios.get(`/app/ThirtyDaysCheck/${this.props.app.id}`);

			if (newStatus === true) {
				window.location.replace(
					`${this.state.url}apps/prequal/declined-submit`,
				);
			} else {
				await this.gdsSubmitCheck();
			}
		} catch (error) {
			this.setState((prevState) => ({ ...prevState }));
			this.gdsRequest();
		}
	};

	gdsSubmitCheck = async () => {
		const { onSaveTerms, OnStartApply, app } = this.props;
		try {
			const response = await (await axios.get(`/decisioning/submit/${app.id}`))
				.data;

			//Support for GDS Decision object
			const status =
				typeof response === "string" ? response : response.data.status;

			if (
				status === SUBMIT_STATUS.APPROVED ||
				status === SUBMIT_STATUS.COUNTER_OFFER
			) {
				onSaveTerms(status);
				OnStartApply(store.getState().app, this.state.branch);
			} else if (status === SUBMIT_STATUS.NO_MATCH) {
				window.location.replace(`${this.state.url}apps/prequal/no-match`);
			} else if (status === SUBMIT_STATUS.DECLINED) {
				window.location.replace(`${this.state.url}apps/prequal/declined/`);
			} else if (status === SUBMIT_STATUS.ERROR) {
				window.location.replace(`${this.state.url}apps/prequal/downtime-error`);
			} else {
				appInsights.trackTrace({
					message: `
              /decisioning/submit Returned a status that wasn't being handled on the client side. status was: ${status}\n
              Current Component State: ${this.state} and Component Props: ${this.props}`,
				});
				goToErrorPage(this.state.url);
			}
		} catch (error) {
			this.setState((prevState) => ({ ...prevState }));
			this.gdsSubmitCheck();
		}
	};

	render() {
		const checkOffer = "";
		const {
			form: { creditReportConsent, telemarketingConsent, singleOrCoApp },
		} = this.props;

		if (
			creditReportConsent.value === null ||
			creditReportConsent.value === "false"
		) {
			document.getElementById("creditReportConsent") &&
				(document.getElementById("creditReportConsent").checked = false);
		} else {
			document.getElementById("creditReportConsent") &&
				(document.getElementById("creditReportConsent").checked = true);
		}

		if (this.state.tcpaFlag === false) {
			if (
				telemarketingConsent.value === null ||
				telemarketingConsent.value === "false"
			) {
				document.getElementById("telemarketingConsent") &&
					(document.getElementById("telemarketingConsent").checked = false);
			} else {
				document.getElementById("telemarketingConsent") &&
					(document.getElementById("telemarketingConsent").checked = true);
			}
		}

		const loadingClasses = this.state.gdsRequestInProcess ? "isLoading" : "";

		const nextButtonDisabled =
			typeof creditReportConsent.value === "undefined" ||
			creditReportConsent.value === null ||
			creditReportConsent.value === "false" ||
			this.state.gdsRequestInProcess;

		const offerButtonText = `Check ${
			singleOrCoApp.value === "Co-Applicant" ? "Our" : "My"
		} Offer`;

		const consent = (
			<div className={loadingClasses} id="loading">
				<div className="step steps consents-group">
					<Box>
						<Center>
							<Box maxW="600px">
								{!this.state.gdsRequestInProcess ? (
									<Box mt={10}>
										<Box>
											<Center>
												<Heading size="md">You're almost done!</Heading>
											</Center>

											<Box mt={10}>
												{" "}
												<Text fontSize="18px" as="b">
													Permission to obtain your consumer reports
												</Text>
											</Box>
											<br />
											<Text fontSize="14px">
												Remember, this won’t affect your credit score!
											</Text>

											<Container mt={25} maxW="600px" bg="#F5F5F5" p={6}>
												<Box p={10}>
													{" "}
													<Grid templateColumns="repeat(15, 1fr)" gap={1}>
														<GridItem colSpan={1}>
															<Checkbox
																style={{ transform: "scale(0.7)" }}
																size="xl"
																onChange={(event) =>
																	this.props.onChanged(
																		event,
																		"creditReportConsent",
																	)
																}
																onBlur={(event) =>
																	this.props.onBlurred(
																		event,
																		"creditReportConsent",
																	)
																}
															></Checkbox>
														</GridItem>
														<GridItem colSpan={14}>
															<Box>
																{singleOrCoApp.value === "Co-Applicant" ? (
																	<Text fontSize="18px">
																		We authorize Republic Finance to obtain our
																		consumer reports from any consumer reporting
																		agency to determine if we pre-qualify for a
																		loan.
																	</Text>
																) : (
																	<Text fontSize="18px">
																		I authorize Republic Finance to obtain my
																		consumer reports from any consumer reporting
																		agency to determine if I pre-qualify for a
																		loan.
																	</Text>
																)}
															</Box>
														</GridItem>
													</Grid>
												</Box>
											</Container>
										</Box>

										<Box mt={20}>
											<Divider mb={20} />
											<Flex>
												<Box>
													<Button
														bg="white"
														color="#2371a5"
														_hover={{ bg: "#3c99d3", color: "white" }}
														size="lg"
														p={4}
														border="1px"
														onClick={() => {
															this.props.save();
															this.props.previous();
														}}
													>
														Back
													</Button>
												</Box>
												<Spacer />
												<Box>
													<Button
														bg="#2371a5"
														color="white"
														_hover={{ bg: "#3c99d3" }}
														size="lg"
														disabled={!!nextButtonDisabled}
														onClick={this.gdsRequest}
														p={4}
													>
														{" "}
														{offerButtonText}
													</Button>
												</Box>
											</Flex>
										</Box>
									</Box>
								) : null}

								{checkOffer}
							</Box>
						</Center>
					</Box>
				</div>
			</div>
		);

		const consentTcpa = (
			<div className={loadingClasses} id="loading">
				<div className="step steps consents-group">
					{!this.state.gdsRequestInProcess ? (
						<Box>
							<Center>
								<Box mt={10} mb={20} maxW="600px">
									<Center>
										<Heading size="md">You're almost done!</Heading>
									</Center>

									<Box mt={10}>
										{" "}
										<Text fontSize="18px" as="b">
											Permission to obtain your consumer reports
										</Text>
									</Box>
									<br />
									<Text fontSize="14px">
										Remember, this won’t affect your credit score!
									</Text>

									<Container mt={25} maxW="600px" bg="#F5F5F5" p={6}>
										<Box p={10}>
											{" "}
											<Grid templateColumns="repeat(15, 1fr)" gap={1}>
												<GridItem colSpan={1}>
													<Checkbox
														style={{ transform: "scale(0.7)" }}
														size="lg"
														onChange={(event) =>
															this.props.onChanged(event, "creditReportConsent")
														}
														onBlur={(event) =>
															this.props.onBlurred(event, "creditReportConsent")
														}
													></Checkbox>
												</GridItem>
												<GridItem colSpan={14}>
													<Box>
														{singleOrCoApp.value === "Co-Applicant" ? (
															<Text fontSize="18px">
																We authorize Republic Finance to obtain our
																consumer reports from any consumer reporting
																agency to determine if we pre-qualify for a
																loan.
															</Text>
														) : (
															<Text fontSize="18px">
																I authorize Republic Finance to obtain my
																consumer reports from any consumer reporting
																agency to determine if I pre-qualify for a loan.
															</Text>
														)}
													</Box>
												</GridItem>
											</Grid>
										</Box>
									</Container>

									<Box>
										{this.props.form.telemarketingConsent.value !== true ? (
											<Box>
												<Box mt={10}>
													<Text fontSize="18px" as="b">
														You haven’t given us permission to contact you using
														automated means.
													</Text>

													<Text fontSize="16px">
														<br />
														Without this consent, we will not be able to call or
														text you about your offer! Automated phone calls and
														texts are the quickest way for us to contact you
														about this offer and future offers. (optional)
													</Text>
												</Box>

												<Container mt={25} maxW="600px" bg="#F5F5F5">
													<Box p={10}>
														<Grid templateColumns="repeat(15, 1fr)" gap={1}>
															<GridItem colSpan={1}>
																<Checkbox
																	style={{ transform: "scale(0.7)" }}
																	size="lg"
																	onChange={(event) =>
																		this.props.onChanged(
																			event,
																			"telemarketingConsent",
																		)
																	}
																	onBlur={(event) =>
																		this.props.onBlurred(
																			event,
																			"telemarketingConsent",
																		)
																	}
																></Checkbox>
															</GridItem>
															<GridItem colSpan={14}>
																<Box>
																	{" "}
																	<Text
																		fontSize="16px"
																		fontWeight="bold"
																		lineHeight="24px"
																	>
																		{" "}
																		By providing my telephone number(s) in
																		connection with this request for
																		pre-qualification and checking this box, I
																		consent to receive calls, text messages,
																		artificial or pre-recorded voice messages,
																		and voicemail transmissions, including calls
																		made by an automated telephone dialing
																		system or an automated system for the
																		selection or dialing of telephone numbers or
																		the playing of a recorded message when a
																		connection is completed to a number called,
																		by or on behalf of Republic Finance,
																		regarding its products and services,
																		including telephonic sales calls and/or
																		marketing communications. I authorize these
																		telemarketing calls and texts even if I have
																		previously included my telephone number(s)
																		on any Do-Not-Call registry or list. I
																		understand I am not required to provide this
																		consent as condition of purchasing any
																		products or service from Republic Finance. I
																		certify that I have authority to provide
																		this consent because I am the regular user
																		of the telephone number(s) as the subscriber
																		or a non-subscriber customary user who has
																		authority to consent to these
																		communications. I also agree to notify
																		Republic Finance if my telephone number(s)
																		change. I have read, understand, and agree
																		to Republic Finance’s{" "}
																		<Link
																			style={{
																				textDecoration: "underline",
																				color: "#2171A6",
																			}}
																			isExternal
																			color="teal.500"
																			href="/legal/text-message-policy"
																		>
																			{" "}
																			Text Message (SMS) Terms of Use.
																		</Link>{" "}
																	</Text>
																</Box>
															</GridItem>
														</Grid>
													</Box>
												</Container>
											</Box>
										) : null}
									</Box>

									<Box mt={20}>
										<Divider mb={20} />
										<Flex>
											<Box>
												<Button
													bg="white"
													color="#2371a5"
													_hover={{ bg: "#3c99d3", color: "white" }}
													size="lg"
													border="1px"
													p={4}
													onClick={() => {
														this.props.save();
														this.props.previous();
													}}
												>
													Back
												</Button>
											</Box>
											<Spacer />
											<Box>
												<Button
													bg="#2371a5"
													color="white"
													_hover={{ bg: "#3c99d3" }}
													size="lg"
													disabled={!!nextButtonDisabled}
													onClick={this.gdsRequest}
													p={4}
												>
													{" "}
													{offerButtonText}
												</Button>
											</Box>
										</Flex>
									</Box>
								</Box>
							</Center>
						</Box>
					) : null}

					{checkOffer}
				</div>
			</div>
		);

		const consentContent = this.state.tcpaFlag ? consentTcpa : consent;

		return (
			<Box>
				{consentContent}
				{this.state.gdsRequestInProcess ? (
					<Box mt={20}>
						<Progress size="lg" isIndeterminate />
						<Center>
							<Box mt={5} maxW="600px">
								<Center>
									<Heading size="24px">
										Please wait while we review your information...
									</Heading>
								</Center>

								<Box mt={5}>
									{" "}
									<Center>
										<Text fontSize="16px" as="b">
											This may take up to 2 minutes.
										</Text>
									</Center>
								</Box>
							</Box>
						</Center>
					</Box>
				) : null}
			</Box>
		);
	}
}
const mapDispatchToProps = (dispatch) => ({
	onSaveTerms: (terms) => dispatch(actions.saveTerms(terms)),
	OnStartApply: (appData, branch) =>
		dispatch(actions.digifiRequest(appData, branch)),
});

export default connect(null, mapDispatchToProps, null, { withRef: true })(
	stepHandler(Consents),
);
