import {
	Box,
	Button,
	Center,
	Checkbox,
	Container,
	Divider,
	Flex,
	HStack,
	Heading,
	InputGroup,
	SimpleGrid,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { Component, createRef } from "react";
import { FaEyeSlash, FaRegEye } from "react-icons/fa";
import { RiLock2Line } from "react-icons/ri";
import { validators } from "../../../utility/forms";
import { InputElement } from "../../UI";
import stepHandler from "../StepHandler.js";
const baseTrackedFields = [
	"coFirstName",
	"coLastName",
	"coSsn",
	"coDateOfBirth",
	"coEmail",
	"coPhone",
	"sameAddressAsPrimary",
];
const allTrackedFields = [
	...baseTrackedFields,
	"coStreet1",
	"coStreet2",
	"coCity",
	"coState",
	"coZipCode",
];

class CoApplicant extends Component {
	constructor() {
		super();
		this.state = { loading: false, hidden: true };
	}

	previousZoneStyles = null;

	state = {
		url: process.env.REACT_APP_WEBSITE_URL,
	};

	componentDidMount() {
		this.props.trackFields("Co-Applicant", allTrackedFields);
		window.scrollTo(0, 0);

		const input = document.getElementById("firstName");
		if (window.innerWidth <= 970) {
			input.blur();
		}

		document.getElementById("ssn").type = "password";
	}

	componentDidUpdate(prevProps) {
		if (
			prevProps.form.sameAddressAsPrimary.value !==
			this.props.form.sameAddressAsPrimary.value
		) {
			const updatedFields =
				this.props.form.sameAddressAsPrimary.value === "true"
					? baseTrackedFields
					: allTrackedFields;
			this.props.trackFields("Co-Applicant", updatedFields, true);
		}
	}

	toggleHidden = () => {
		this.setState({ hidden: !this.state.hidden });

		if (document.getElementById("ssn").type === "password") {
			document.getElementById("ssn").type = "text";
		} else {
			document.getElementById("ssn").type = "password";
		}
	};

	render() {
		const nextButton = document.getElementById("next-button");

		const hasValidDob =
			this.props.form.coDateOfBirth.value &&
			validators.validateMinimumAge(
				this.props.form.coDateOfBirth.value,
				this.props.config.minimumAge,
			).isValid;
		const hasValidNames =
			validators.hasValue(this.props.form.coFirstName.value) &&
			validators.hasValue(this.props.form.coLastName.value);

		//Check for on-screen errors
		let validCheck = false;
		const errors = document.getElementsByClassName("red");
		if (errors.length === 0) {
			validCheck = true;
		} else {
			validCheck = false;
		}

		//Form "Next" validation check
		nextButton.disabled = true;
		if (
			hasValidNames &&
			hasValidDob &&
			/^\d{3}-\d{2}-\d{4}$/.test(this.props.form.coSsn.value) &&
			/^(([^<>()[\]\.,;:\s@\"+]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
				this.props.form.coEmail.value,
			) &&
			/^\(\d{3}\)\s\d{3}-\d{4}/.test(this.props.form.coPhone.value) &&
			this.props.form.coPhone.value !== "" &&
			/^\d{5}$/.test(this.props.form.coZipCode.value) &&
			this.props.form.coStreet1.value !== "" &&
			this.props.form.coCity.value !== "" &&
			this.props.form.coState.value !== "" &&
			this.props.form.sameAddressAsPrimary.value === "false"
		) {
			nextButton.disabled = false;
		}

		if (
			hasValidNames &&
			hasValidDob &&
			/^\d{3}-\d{2}-\d{4}$/.test(this.props.form.coSsn.value) &&
			/^(([^<>()[\]\.,;:\s@\"+]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/.test(
				this.props.form.coEmail.value,
			) &&
			/^\(\d{3}\)\s\d{3}-\d{4}/.test(this.props.form.coPhone.value) &&
			this.props.form.coPhone.value !== "" &&
			this.props.form.sameAddressAsPrimary.value === "true"
		) {
			nextButton.disabled = false;
			validCheck = true;
		}

		let address = (
			<Box>
				<SimpleGrid mt={10} columns={{ sm: 1, md: 2 }} gap={{ sm: 0, md: 150 }}>
					<Box minW={300}>
						<InputElement
							formElement={this.props.form.coStreet1}
							onChange={(event) => this.props.onChanged(event, "coStreet1")}
							onBlur={(event) => this.props.onBlurred(event, "coStreet1")}
						/>
					</Box>

					<Box>
						<InputElement
							formElement={this.props.form.coStreet2}
							onChange={(event) => this.props.onChanged(event, "coStreet2")}
							onBlur={(event) => this.props.onBlurred(event, "coStreet2")}
						/>
					</Box>
				</SimpleGrid>
				<SimpleGrid columns={{ sm: 1, md: 3 }} gap={5}>
					<Box>
						<InputElement
							formElement={this.props.form.coCity}
							onChange={(event) => this.props.onChanged(event, "coCity")}
							onBlur={(event) => this.props.onBlurred(event, "coCity")}
						/>
					</Box>
					<Box>
						<InputElement
							formElement={this.props.form.coState}
							onChange={(event) => this.props.onChanged(event, "coState")}
							onBlur={(event) => this.props.onBlurred(event, "coState")}
						/>
					</Box>

					<Box>
						<InputElement
							formElement={this.props.form.coZipCode}
							onChange={(event) => this.props.onChanged(event, "coZipCode")}
							onBlur={(event) => this.props.onBlurred(event, "coZipCode")}
						/>
					</Box>
				</SimpleGrid>
			</Box>
		);
		if (this.props.form.sameAddressAsPrimary.value === "true") {
			address = (
				<Box>
					<SimpleGrid
						mt={10}
						columns={{ sm: 1, md: 2 }}
						gap={{ sm: 0, md: 150 }}
					>
						<Box minW={300}>
							<InputElement
								readOnly
								formElement={this.props.form.street1}
								onChange={(event) => this.props.onChanged(event, "street1")}
								onBlur={(event) => this.props.onBlurred(event, "street1")}
							/>
						</Box>

						<Box>
							<InputElement
								readOnly
								formElement={this.props.form.street2}
								onChange={(event) => this.props.onChanged(event, "street2")}
								onBlur={(event) => this.props.onBlurred(event, "street2")}
							/>
						</Box>
					</SimpleGrid>
					<SimpleGrid columns={{ sm: 1, md: 3 }} gap={5}>
						<Box>
							<InputElement
								readOnly
								formElement={this.props.form.city}
								onChange={(event) => this.props.onChanged(event, "city")}
								onBlur={(event) => this.props.onBlurred(event, "city")}
							/>
						</Box>
						<Box>
							<InputElement
								readOnly
								formElement={this.props.form.state}
								onChange={(event) => this.props.onChanged(event, "state")}
								onBlur={(event) => this.props.onBlurred(event, "state")}
							/>
						</Box>

						<Box>
							<InputElement
								readOnly
								formElement={this.props.form.zipCode}
								onChange={(event) => this.props.onChanged(event, "zipCode")}
								onBlur={(event) => this.props.onBlurred(event, "zipCode")}
							/>
						</Box>
					</SimpleGrid>
				</Box>
			);
		}

		return (
			<Box mb={20}>
				<Center>
					<Box maxW="600px">
						<Center>
							<Heading size="md" mb={14} mt={10}>
								Let’s get some information about the co-applicant
							</Heading>
						</Center>
						<Box mb={8}>
							<Text fontSize="18px" as="b">
								ABOUT THE CO-APPLICANT
							</Text>
						</Box>

						<SimpleGrid columns={{ sm: 1, md: 3 }} spacing={{ sm: 0, md: 5 }}>
							<Box>
								<InputElement
									formElement={this.props.form.coFirstName}
									onChange={(event) =>
										this.props.onChanged(event, "coFirstName")
									}
									onBlur={(event) => this.props.onBlurred(event, "coFirstName")}
								/>
							</Box>
							<Box>
								<InputElement
									formElement={this.props.form.coLastName}
									onChange={(event) =>
										this.props.onChanged(event, "coLastName")
									}
									onBlur={(event) => this.props.onBlurred(event, "coLastName")}
								/>
							</Box>
							<Box>
								<InputElement
									formElement={this.props.form.coSuffix}
									onChange={(event) => this.props.onChanged(event, "coSuffix")}
									onBlur={(event) => this.props.onBlurred(event, "coSuffix")}
								/>
							</Box>
						</SimpleGrid>
						<Text mt={2} color="#777777">
							Enter your name as it appears on your state or US
							government-issued ID.
						</Text>
						<Box mt={6} maxWidth={250}>
							<InputElement
								formElement={this.props.form.coEmail}
								onChange={(event) => this.props.onChanged(event, "coEmail")}
								onBlur={(event) => this.props.onBlurred(event, "coEmail")}
							/>
						</Box>
						<Box maxWidth={250}>
							<InputElement
								formElement={this.props.form.coPhone}
								onChange={(event) => this.props.onChanged(event, "coPhone")}
								onBlur={(event) => this.props.onBlurred(event, "coPhone")}
							/>
						</Box>
						<Box mt={6} maxWidth={250}>
							<InputElement
								formElement={this.props.form.coDateOfBirth}
								onChange={(event) =>
									this.props.onChanged(event, "coDateOfBirth")
								}
								onBlur={(event) => this.props.onBlurred(event, "coDateOfBirth")}
							/>
						</Box>

						<Container mt={25} mb={25} maxW="600px" bg="#F5F5F5" p={6}>
							<Box p={5}>
								<Text fontSize="sm">
									We’ll use this to verify your identity and determine your
									offer. Remember, this{" "}
									<span style={{ fontWeight: "bold" }}>
										doesn’t impact your credit score!
									</span>
								</Text>
								<Text fontSize="sm" mb={3} mt={4}>
									Social Security Number
								</Text>

								<Box>
									{" "}
									<Box>
										<InputGroup size="md">
											<Box minWidth={270}>
												{" "}
												<InputElement
													formElement={this.props.form.coSsn}
													onChange={(event) =>
														this.props.onChanged(event, "coSsn")
													}
													onBlur={(event) =>
														this.props.onBlurred(event, "coSsn")
													}
												/>{" "}
											</Box>
											<Box>
												{this.state.hidden ? (
													<Box m={4} ml={-7} onClick={this.toggleHidden}>
														<FaEyeSlash />
													</Box>
												) : (
													<Box m={4} ml={-7} onClick={this.toggleHidden}>
														<FaRegEye />
													</Box>
												)}
											</Box>
										</InputGroup>
									</Box>
								</Box>

								<Box mt={5}>
									<Divider mb={7} />
									<HStack>
										{" "}
										<RiLock2Line />
										<Text fontSize="sm">
											Your information is safe and secure
										</Text>
									</HStack>
								</Box>
							</Box>
						</Container>

						<Text fontSize="18px" as="b">
							CO-APPLICANT MAILING ADDRESS
						</Text>
						<Box mt={8}>
							<Checkbox
								sx={{
									".chakra-checkbox__control": {
										width: "20px",
										height: "20px",
									},
								}}
								size="md"
								onChange={(event) =>
									this.props.onChanged(event, "sameAddressAsPrimary")
								}
								onBlur={(event) =>
									this.props.onBlurred(event, "sameAddressAsPrimary")
								}
							>
								<Text mt={1} ml={-4} fontSize="14px" fontWeight="normal">
									Address same as primary applicant
								</Text>
							</Checkbox>
						</Box>

						{address}
						<Box mt={5}>
							<Divider pt={5} />
						</Box>
						<Container maxWidth={"600px"}>
							<Flex>
								<Button
									id="back-button"
									onClick={() => {
										this.props.save();
										this.props.previous();
									}}
									bg="white"
									color="#2371a5"
									_hover={{ bg: "#3c99d3", color: "white" }}
									size="lg"
									border="1px"
									mt={10}
									p={4}
								>
									Back
								</Button>
								<Spacer />
								<Button
									id="next-button"
									onClick={() => {
										this.props.next();
										this.setState({ loading: true });
									}}
									isLoading={this.state.loading}
									loadingText="Continue"
									bg="#2371a5"
									color="white"
									_hover={{ bg: "#3c99d3" }}
									size="lg"
									mt={10}
									p={4}
								>
									Continue
								</Button>
							</Flex>
						</Container>
					</Box>
				</Center>
			</Box>
		);
	}
}

export default stepHandler(CoApplicant);
