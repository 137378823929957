import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import { render } from "react-dom";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { initializeAppInsights } from "./app-insights";
import store from "./store";
//Theme
import { checkboxTheme } from "./theme/components/Checkbox";

const root = ReactDOM.createRoot(document.getElementById("root"));
initializeAppInsights();

const theme = extendTheme({
	components: {
		Checkbox: checkboxTheme,
	},
});

root.render(
	<Provider store={store}>
		<ChakraProvider theme={theme}>
			<App />
		</ChakraProvider>
	</Provider>,
);
