import React from "react";
import { OutboundLink } from "react-ga";

export function TelemarketingConsentLabel() {
	return (
		<span style={{ fontWeight: "normal" }}>
			By providing my telephone number(s) in connection with this request for
			pre-qualification and checking this box, I consent to receive calls, text
			messages, artificial or pre-recorded voice messages, and voicemail
			transmissions, including calls made by an automated telephone dialing
			system or an automated system for the selection or dialing of telephone
			numbers or the playing of a recorded message when a connection is
			completed to a number called, by or on behalf of Republic Finance,
			regarding its products and services, including telephonic sales calls
			and/or marketing communications. I authorize these telemarketing calls and
			texts even if I have previously included my telephone number(s) on any
			Do-Not-Call registry or list. I understand I am not required to provide
			this consent as condition of purchasing any products or service from
			Republic Finance. I certify that I have authority to provide this consent
			because I am the regular user of the telephone number(s) as the subscriber
			or a non-subscriber customary user who has authority to consent to these
			communications. I also agree to notify Republic Finance if my telephone
			number(s) change. I have read, understand, and agree to Republic Finance’s
			<br></br>
			<OutboundLink
				eventLabel="Clicked SMS Terms of Use Link"
				rel="noopener noreferrer"
				target="_blank"
				to="/legal/text-message-policy"
			>
				Text Message (SMS) Terms of Use
			</OutboundLink>
			.
		</span>
	);
}
