import { Box, HStack, Text } from "@chakra-ui/react";
import React, { Component } from "react";
import { MdLocationPin } from "react-icons/md";

class Branch extends Component {
	branchCheck = null;
	render() {
		return (
			<Box bg="#F5F5F5" p={7} borderRadius="6px">
				<div
					data-toggle="wizard-radio"
					onClick={() => this.branchCheck.click()}
				>
					<HStack>
						<Box>
							<input
								ref={(node) => {
									this.branchCheck = node;
								}}
								type="radio"
								name="branch"
								className="required-sm"
								value={this.props.value}
								checked={this.props.selected}
								onClick={() => this.props.onSelected(this.props.value)}
								readOnly
							/>
						</Box>
						<Box pl={5}>
							<p>
								{this.props.address}
								<br />
								{this.props.city}, {this.props.state} {this.props.zipCode}
								<br />
								{this.props.phone}
								<HStack>
									<MdLocationPin /> <Text>{this.props.distance} miles </Text>
								</HStack>
							</p>
						</Box>
					</HStack>
				</div>
			</Box>
		);
	}
}

export default Branch;
