import { SeverityLevel } from "@microsoft/applicationinsights-web";

import { appInsights } from "../../app-insights";
import { sanitizeAppData } from "../../utility/sanitizeAppData";

export function errorMiddleware(store) {
	return (next) => (action) => {
		if (!action.type.includes("FAILURE")) {
			return next(action);
		}

		const { app, config, ...currentState } = store.getState();
		const sanitizedAppData = sanitizeAppData(app);

		appInsights.trackException({
			exception: new Error(action.error),
			properties: {
				actionName: action.type,
				applicationId: sanitizedAppData.id || "Unknown",
				applicationState: JSON.stringify({
					...currentState,
					app: sanitizeAppData,
				}),
				isReduxAction: true,
			},
			severityLevel:
				(action.meta && action.meta.severityLevel) || SeverityLevel.Error,
		});

		return next(action);
	};
}
